import React from "react";
import adList from "../json/adList.json";
import rigList from "../json/rigList.json";

function AirportsAndRigs() {
  const airportView = () => {
    return (
      <div>
        {adList.map((item) => (
          <div style={{ borderBottom: "1px solid white", paddingBottom: 15 }}>
            <h1 style={{ textAlign: "left", marginBottom: 0 }}>{item.name}</h1>
            <p style={styles.latlong}>
              {item.latlong.latitude}N / {item.latlong.longitude}E
            </p>
            <h3 style={styles.titles}>PRIMARY APPROACHES</h3>
            {"one" in item ? (
              <p style={styles.content}>
                RWY {item.one.rwy <= 9 ? "0" + item.one.rwy : item.one.rwy}
                &emsp;
                {item.one.appr} - {item.one.da}
                '({item.one.dh}')/{item.one.vis}m
              </p>
            ) : null}
            {"two" in item ? (
              <p style={styles.content}>
                RWY {item.two.rwy <= 9 ? "0" + item.two.rwy : item.two.rwy}
                &emsp;
                {item.two.appr} - {item.two.da}
                '({item.two.dh}')/{item.two.vis}m
              </p>
            ) : null}
            {"oneFms" in item ? (
              <h3 style={styles.titles}>ONE FMS APPROACHES</h3>
            ) : null}
            {"oneFms" in item && "one" in item.oneFms ? (
              <p style={styles.content}>
                RWY{" "}
                {item.oneFms.one.rwy <= 9
                  ? "0" + item.oneFms.one.rwy
                  : item.oneFms.one.rwy}
                &emsp;
                {item.oneFms.one.appr} - {item.oneFms.one.da}
                '({item.oneFms.one.dh}')/{item.oneFms.one.vis}m
              </p>
            ) : null}
            {"oneFms" in item && "two" in item.oneFms ? (
              <p style={styles.content}>
                RWY{" "}
                {item.oneFms.two.rwy <= 9
                  ? "0" + item.oneFms.two.rwy
                  : item.oneFms.two.rwy}
                &emsp;
                {item.oneFms.two.appr} - {item.oneFms.two.da}
                '({item.oneFms.two.dh}')/{item.oneFms.two.vis}m
              </p>
            ) : null}
            {"waiving" in item ? (
              <h3 style={styles.titles}>APPROACHES USED FOR WAIVING RULE #2</h3>
            ) : null}
            {"waiving" in item && "one" in item.waiving ? (
              <p style={styles.content}>
                RWY{" "}
                {item.waiving.one.rwy <= 9
                  ? "0" + item.waiving.one.rwy
                  : item.waiving.one.rwy}
                &emsp;
                {item.waiving.one.appr} - {item.waiving.one.da}
                '({item.waiving.one.dh}')/{item.waiving.one.vis}m
              </p>
            ) : null}
            {"waiving" in item && "two" in item.waiving ? (
              <p style={styles.content}>
                RWY{" "}
                {item.waiving.two.rwy <= 9
                  ? "0" + item.waiving.two.rwy
                  : item.waiving.two.rwy}
                &emsp;
                {item.waiving.two.appr} - {item.waiving.two.da}
                '({item.waiving.two.dh}')/{item.waiving.two.vis}m
              </p>
            ) : null}
            {"waiving" in item && "oneFms" in item.waiving ? (
              <h3 style={styles.titles}>
                ONE FMS APPROACHES USED FOR WAIVING RULE #2
              </h3>
            ) : null}
            {"waiving" in item &&
            "oneFms" in item.waiving &&
            "one" in item.waiving.oneFms ? (
              <p style={styles.content}>
                RWY{" "}
                {item.waiving.oneFms.one.rwy <= 9
                  ? "0" + item.waiving.oneFms.one.rwy
                  : item.waiving.oneFms.one.rwy}
                &emsp;
                {item.waiving.oneFms.one.appr} - {item.waiving.oneFms.one.da}
                '({item.waiving.oneFms.one.dh}')/
                {item.waiving.oneFms.one.vis}m
              </p>
            ) : null}
            {"waiving" in item &&
            "oneFms" in item.waiving &&
            "two" in item.waiving.oneFms ? (
              <p style={styles.content}>
                RWY{" "}
                {item.waiving.oneFms.two.rwy <= 9
                  ? "0" + item.waiving.oneFms.two.rwy
                  : item.waiving.oneFms.two.rwy}
                &emsp;
                {item.waiving.oneFms.two.appr} - {item.waiving.oneFms.two.da}
                '({item.waiving.oneFms.two.dh}')/
                {item.waiving.oneFms.two.vis}m
              </p>
            ) : null}
          </div>
        ))}
      </div>
    );
  };

  const rigView = () => {
    return (
      <div>
        {rigList.map((item) => (
          <div style={{ borderBottom: "1px solid white", paddingBottom: 15 }}>
            <h1 style={{ textAlign: "left", marginBottom: 0 }}>{item.name}</h1>
            <p style={styles.latlong}>
              {item.latlong.latitude}N / {item.latlong.longitude}E
            </p>
            <h3 style={styles.titles}>RIG INFORMATION</h3>
            {"deckHdg" in item || "deckHeight" in item ? (
              <p style={styles.content}>
                {"deckHdg" in item ? `HDG: ${item.deckHdg}° / ` : null}
                {"deckHeight" in item ? `Height: ${item.deckHeight}'` : null}
              </p>
            ) : null}
            {"fixedRig" in item ? (
              <p style={styles.content}>
                Fixed: {item.fixedRig ? "true" : "false"}
              </p>
            ) : null}
            {console.log(item)}
            {"lim" in item || "daylightOnly" in item ? (
              <h3 style={styles.titles}>LIMITATIONS</h3>
            ) : null}
            {"daylightOnly" in item && item.daylightOnly ? (
              <p style={styles.limTitle}>Daylight ops only!</p>
            ) : null}
            {"lim" in item
              ? item.lim.map((lim) => {
                  return (
                    <div style={styles.limItem}>
                      <p style={styles.limTitle}>{lim.type}</p>
                      <p style={styles.content}>
                        {lim.from}° - {lim.to}° |{" "}
                        {lim.type === "caution" || lim.type === "table 2"
                          ? "See table / note"
                          : null}
                        {"lim" in lim ? `${lim.lim}kts` : null}
                        {"velFrom" in lim || "velTo" in lim ? (
                          <span style={styles.content}>
                            {"velFrom" in lim ? `${lim.velFrom}kts - ` : null}
                            {"velTo" in lim ? `${lim.velTo}kts` : null}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  );
                })
              : null}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <h1>AIRPORTS</h1>
      {airportView()}
      <h1>RIGS</h1>
      {rigView()}
    </div>
  );
}

const styles = {
  latlong: {
    textAlign: "left",
    fontSize: "0.7rem",
    margin: 0,
  },
  titles: {
    textAlign: "left",
    fontWeight: "bold",
  },
  content: {
    textAlign: "left",
    margin: 0,
    marginTop: 2,
    padding: 0,
  },
  limItem: {
    marginTop: 10,
  },
  limTitle: {
    textAlign: "left",
    fontWeight: "bold",
    margin: 0,
  },
};

export default AirportsAndRigs;
