import React, { useState } from "react";
import Sigchart from "./Sigchart";
import WaveChart from "./WaveChart";
import HtiChart from "./HtiChart";
import GafChart from "./GafChart";

const Charts = () => {
    const [chartType, setChartType] = useState('sigchart');

    const changeChart = (type) => {
        setChartType(type);
    };

    return (
        <div style={styles.div}>
            <div style={styles.buttonMenu}>
                <h3 onClick={() => changeChart("sigchart")} style={chartType === "sigchart" ? styles.dividerUnderline : styles.divider}>Sigchart</h3>
                <h3 onClick={() => changeChart("wave")} style={chartType === "wave" ? styles.dividerUnderline : styles.divider}>Wave</h3>
                <h3 onClick={() => changeChart("hti")} style={chartType === "hti" ? styles.dividerUnderline : styles.divider}>HTI</h3>
                <h3 onClick={() => changeChart("gaf")} style={chartType === "gaf" ? styles.dividerUnderline : styles.divider}>GAF</h3>
            </div>
            {chartType === "sigchart" ? <Sigchart /> : null}
            {chartType === "wave" ? <WaveChart /> : null}
            {chartType === "hti" ? <HtiChart /> : null}
            {chartType === "gaf" ? <GafChart /> : null}
        </div>
    )
}

const styles = {
    div: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 10,
        paddingBottom: 0,
        minHeight: "100vh"
    },
    buttonMenu: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    divider: {
        border: "2px solid transparent",
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 7,
        paddingBottom: 7,
        cursor: 'pointer'
    },
    dividerUnderline: {
        borderRadius: 8,
        border: "2px solid white",
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 7,
        paddingBottom: 7,
        backgroundColor: "#000",
        cursor: 'pointer'
    },
    paddingNoDivider: {
        borderRadius: 8,
        paddingRight: 20,
        paddingLeft: 22,
        paddingTop: 7,
        paddingBottom: 7,
        cursor: 'pointer'
    },
    paddingNoDividerUnderline: {
        borderRadius: 8,
        border: "2px solid white",
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 5,
        paddingBottom: 5,
        cursor: 'pointer'
    }
}

export default Charts;