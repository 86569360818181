import adList from "../json/adList.json";
import rigList from "../json/rigList.json";

const REG_WIND = /\b\d{5}KT\b/s;
const REG_NO_WIND = /\/\/\/\/\/KT/s;
const REG_GUST = /\b\d{5}G\d{2}KT\b/s;
const REG_VRB = /\bVRB\d{2}KT\b/s;
const REG_VRB_GUST = /\bVRB\d{2}G\d{2}KT\b/s;
// const REG_VIS = /^\d{4}$/s;
// const REG_CAVOK = /^CAVOK$/s;
const TS = /TS/s;

const stringToWordArray = (objIn) => {
  const obj = { ...objIn };
  obj.ts = false;
  if (obj.data !== "No data available") {
    const splitted = obj.data.split(" ");
    obj.weatherWordArray = splitted;
    obj.weatherWordArray.forEach((item) => {
      if (TS.test(item)) {
        obj.ts = true;
      }
    });
  }
  return obj;
};

const sortTempoBecmgProb = (tafSortedTimeArray) => {
  const regArray = [/TEMPO/g, /BECMG/g, /PROB\d{2}/g];
  const tafSorter = tafSortedTimeArray;
  regArray.forEach((value) => {
    tafSorter.forEach((array, index) => {
      array.forEach((innerArray) => {
        if (value.test(innerArray)) {
          if (index + 1) {
            tafSorter[index + 1].unshift(innerArray);
          } else {
            tafSorter.push([innerArray]);
          }
          tafSorter[index].pop();
        }
      });
    });
  });
  return tafSorter;
};

const splitTafTimes = (objIn) => {
  const REG_TIMES = /\d{4}\/\d{4}/g;
  const obj = { ...objIn };
  let subArray = [];
  const mainArray = [];
  if ("weatherWordArray" in obj) {
    obj.weatherWordArray.forEach((item, i) => {
      if (i === 0) {
        subArray.push(item);
        mainArray.push(subArray);
        subArray = [];
      } else {
        if (REG_TIMES.test(item)) {
          if (subArray.length > 0) {
            mainArray.push(subArray);
          }
          subArray = [];
          subArray.push(item);
          if (i === obj.weatherWordArray.length - 1) {
            mainArray.push(subArray);
          }
        } else {
          subArray.push(item);
          if (i === obj.weatherWordArray.length - 1) {
            mainArray.push(subArray);
          }
        }
      }
    });
    const res = sortTempoBecmgProb(mainArray);
    let sortObj = {};
    obj.sort = {};
    obj.sort.content = [];
    res.forEach((item, i) => {
      if (i === 0) {
        obj.sort.init = item;
      } else {
        let timeLog = false;
        item.forEach((innerItem, j) => {
          if (REG_TIMES.test(innerItem)) {
            if (j !== 0) {
              sortObj.type = item.slice(0, j);
              sortObj.time = innerItem;
              timeLog = true;
              if (j === item.length - 1) {
                sortObj.wx = [];
                obj.sort.content.push(sortObj);
                sortObj = {};
                timeLog = false;
              }
            } else {
              sortObj.time = innerItem;
              timeLog = true;
              if (j === item.length - 1) {
                sortObj.wx = [];
                obj.sort.content.push(sortObj);
                sortObj = {};
                timeLog = false;
              }
            }
          } else if (timeLog) {
            sortObj.wx = item.slice(j);
            obj.sort.content.push(sortObj);
            sortObj = {};
            timeLog = false;
          }
        });
      }
    });
  }
  return obj;
};

const setInitDate = (objIn) => {
  const obj = { ...objIn };
  if ("sort" in obj) {
    const date = parseInt(obj.sort.init[0].slice(0, 2), 10);
    const hour = parseInt(obj.sort.init[0].slice(2, 4), 10);
    const min = parseInt(obj.sort.init[0].slice(4, 6), 10);
    const objTime = parseInt(obj.time.date, 10);
    let initTime;

    if (date === objTime) {
      initTime = new Date(
        Date.UTC(obj.time.year, obj.time.month - 1, date, hour, min)
      );
    } else if (date === objTime + 1) {
      initTime = new Date(
        Date.UTC(obj.time.year, obj.time.month - 1, date, hour, min)
      );
    } else if (date === objTime - 1) {
      initTime = new Date(
        Date.UTC(obj.time.year, obj.time.month - 1, date, hour, min)
      );
    } else if (date > objTime + 20) {
      initTime = new Date(
        Date.UTC(obj.time.year, obj.time.month - 2, date, hour, min)
      );
    } else if (date < objTime - 20) {
      initTime = new Date(
        Date.UTC(obj.time.year, obj.time.month, date, hour, min)
      );
    }

    obj.sort.initTime = initTime;
    return obj;
  }
  return obj;
};

const setTafStartEndTime = (objIn) => {
  const obj = { ...objIn };
  if ("sort" in obj) {
    obj.sort.content.forEach((item) => {
      const startDate = parseInt(item.time.slice(0, 2), 10);
      const startHour = parseInt(item.time.slice(2, 4), 10);
      const endDate = parseInt(item.time.slice(5, 7), 10);
      const endHour = parseInt(item.time.slice(7, 9), 10);
      const objTime = parseInt(obj.time.date, 10);
      let startTime;
      let endTime;
      //  Set start time
      if (startDate === objTime) {
        startTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 1, startDate, startHour)
        );
      } else if (startDate === objTime + 1) {
        startTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 1, startDate, startHour)
        );
      } else if (startDate === objTime - 1) {
        startTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 1, startDate, startHour)
        );
      } else if (startDate > objTime + 20) {
        startTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 2, startDate, startHour)
        );
      } else if (startDate < objTime - 20) {
        startTime = new Date(
          Date.UTC(obj.time.year, obj.time.month, startDate, startHour)
        );
      }
      //  Set end time
      if (endDate === objTime) {
        endTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 1, endDate, endHour)
        );
      } else if (endDate === objTime + 1) {
        endTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 1, endDate, endHour)
        );
      } else if (endDate === objTime - 1) {
        endTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 1, endDate, endHour)
        );
      } else if (endDate > objTime + 20) {
        endTime = new Date(
          Date.UTC(obj.time.year, obj.time.month - 2, endDate, endHour)
        );
      } else if (endDate < objTime - 20) {
        endTime = new Date(
          Date.UTC(obj.time.year, obj.time.month, endDate, endHour)
        );
      }
      item.start = startTime;
      item.end = endTime;
    });
    return obj;
  }
  return obj;
};

const tafWind = (objIn) => {
  const obj = { ...objIn };
  if ("sort" in obj) {
    obj.sort.content.forEach((item) => {
      if (!item.wind) {
        item.wx.forEach((innerItem) => {
          if (REG_WIND.test(innerItem)) {
            item.wind = innerItem;
          } else if (REG_GUST.test(innerItem)) {
            item.wind = innerItem;
          } else if (REG_VRB.test(innerItem)) {
            item.wind = innerItem;
          } else if (REG_VRB_GUST.test(innerItem)) {
            item.wind = innerItem;
          } else if (REG_NO_WIND.test(innerItem)) {
            item.wind = innerItem;
          }
        });
      }
    });
  }
  return obj;
};

const addSecondApprForWaiving = (objIn, oneFmsIn) => {
  const obj = { ...objIn };
  const oneFms = oneFmsIn;
  let rwyObj = {};
  let tempWind = 0;
  let tempRwyDir = 0;
  if ("sort" in obj) {
    obj.sort.content.forEach((item, i) => {
      if ("wind" in item) {
        rwyObj = {};
        if (REG_WIND.test(item.wind) || REG_GUST.test(item.wind)) {
          adList.forEach((adObj) => {
            if (obj.ad === adObj.name) {
              if ("waiving" in adObj) {
                let windDir = undefined;
                let windVel = undefined;
                let florøAcceptableTailwind = false;
                tempWind = item.wind.slice(0, 3);
                tempRwyDir = adObj.one.rwy * 10;

                if (REG_WIND.test(item.wind)) {
                  windDir = tempWind;
                  windVel = item.wind.slice(3, 5);
                } else {
                  windDir = tempWind;
                  windVel = item.wind.slice(6, 8);
                }

                if (windDir !== undefined && windVel !== undefined) {
                  const headwind = tafHeadwind(windDir, windVel, adObj.one.rwy);
                  if (headwind <= 20) {
                    florøAcceptableTailwind = true;
                  }
                }

                const difference = Math.abs(tempWind - tempRwyDir);
                if (difference < 90 || difference > 270) {
                  if (oneFms && adObj.waiving.one.appr.includes("LPV")) {
                    if (adObj.waiving.oneFms.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.waiving.oneFms.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.waiving.oneFms.one.rwy;
                    }
                    rwyObj.vis = adObj.waiving.oneFms.one.vis;
                    rwyObj.da = adObj.waiving.oneFms.one.da;
                    rwyObj.dh = adObj.waiving.oneFms.one.dh;
                    rwyObj.appr = adObj.waiving.oneFms.one.appr;
                  } else {
                    if (adObj.waiving.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.waiving.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.waiving.one.rwy;
                    }
                    rwyObj.vis = adObj.waiving.one.vis;
                    rwyObj.da = adObj.waiving.one.da;
                    rwyObj.dh = adObj.waiving.one.dh;
                    rwyObj.appr = adObj.waiving.one.appr;
                  }
                } else if (difference === 90 || difference === 270) {
                  if (adObj.waiving.one.dh <= adObj.waiving.two.dh) {
                    if (oneFms && adObj.waiving.one.appr.includes("LPV")) {
                      if (adObj.waiving.oneFms.one.rwy < 10) {
                        rwyObj.rwy = `0${adObj.waiving.oneFms.one.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.waiving.oneFms.one.rwy;
                      }
                      rwyObj.vis = adObj.waiving.oneFms.one.vis;
                      rwyObj.da = adObj.waiving.oneFms.one.da;
                      rwyObj.dh = adObj.waiving.oneFms.one.dh;
                      rwyObj.appr = adObj.waiving.oneFms.one.appr;
                    } else {
                      if (adObj.waiving.one.rwy < 10) {
                        rwyObj.rwy = `0${adObj.waiving.one.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.waiving.one.rwy;
                      }
                      rwyObj.vis = adObj.waiving.one.vis;
                      rwyObj.da = adObj.waiving.one.da;
                      rwyObj.dh = adObj.waiving.one.dh;
                      rwyObj.appr = adObj.waiving.one.appr;
                    }
                  } else {
                    if (oneFms && adObj.waiving.two.appr.includes("LPV")) {
                      if (adObj.waiving.oneFms.two.rwy < 10) {
                        rwyObj.rwy = `0${adObj.waiving.oneFms.two.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.waiving.oneFms.two.rwy;
                      }
                      rwyObj.vis = adObj.waiving.oneFms.two.vis;
                      rwyObj.da = adObj.waiving.oneFms.two.da;
                      rwyObj.dh = adObj.waiving.oneFms.two.dh;
                      rwyObj.appr = adObj.waiving.oneFms.two.appr;
                    } else {
                      if (adObj.waiving.two.rwy < 10) {
                        rwyObj.rwy = `0${adObj.waiving.two.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.waiving.two.rwy;
                      }
                      rwyObj.vis = adObj.waiving.two.vis;
                      rwyObj.da = adObj.waiving.two.da;
                      rwyObj.dh = adObj.waiving.two.dh;
                      rwyObj.appr = adObj.waiving.two.appr;
                    }
                  }
                  item.rwy.waiving = rwyObj;
                } else {
                  if (oneFms && adObj.waiving.two.appr.includes("LPV")) {
                    if (adObj.name === "ENFL") {
                      if (florøAcceptableTailwind) {
                        if (adObj.waiving.oneFms.one.rwy < 10) {
                          rwyObj.rwy = `0${adObj.waiving.oneFms.one.rwy}`;
                        } else {
                          rwyObj.rwy = adObj.waiving.oneFms.one.rwy;
                        }
                        rwyObj.vis = adObj.waiving.oneFms.one.vis;
                        rwyObj.da = adObj.waiving.oneFms.one.da;
                        rwyObj.dh = adObj.waiving.oneFms.one.dh;
                        rwyObj.appr = adObj.waiving.oneFms.one.appr;
                      } else {
                        if (adObj.waiving.oneFms.two.rwy < 10) {
                          rwyObj.rwy = `0${adObj.waiving.oneFms.two.rwy}`;
                        } else {
                          rwyObj.rwy = adObj.waiving.oneFms.two.rwy;
                        }
                        rwyObj.vis = adObj.waiving.oneFms.two.vis;
                        rwyObj.da = adObj.waiving.oneFms.two.da;
                        rwyObj.dh = adObj.waiving.oneFms.two.dh;
                        rwyObj.appr = adObj.waiving.oneFms.two.appr;
                      }
                    } else {
                      if (adObj.waiving.oneFms.two.rwy < 10) {
                        rwyObj.rwy = `0${adObj.waiving.oneFms.two.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.waiving.oneFms.two.rwy;
                      }
                      rwyObj.vis = adObj.waiving.oneFms.two.vis;
                      rwyObj.da = adObj.waiving.oneFms.two.da;
                      rwyObj.dh = adObj.waiving.oneFms.two.dh;
                      rwyObj.appr = adObj.waiving.oneFms.two.appr;
                    }
                  } else {
                    if (adObj.name === "ENFL") {
                      if (florøAcceptableTailwind) {
                        if (adObj.waiving.one.rwy < 10) {
                          rwyObj.rwy = `0${adObj.waiving.one.rwy}`;
                        } else {
                          rwyObj.rwy = adObj.waiving.one.rwy;
                        }
                        rwyObj.vis = adObj.waiving.one.vis;
                        rwyObj.da = adObj.waiving.one.da;
                        rwyObj.dh = adObj.waiving.one.dh;
                        rwyObj.appr = adObj.waiving.one.appr;
                      } else {
                        if (adObj.waiving.two.rwy < 10) {
                          rwyObj.rwy = `0${adObj.waiving.two.rwy}`;
                        } else {
                          rwyObj.rwy = adObj.waiving.two.rwy;
                        }
                        rwyObj.vis = adObj.waiving.two.vis;
                        rwyObj.da = adObj.waiving.two.da;
                        rwyObj.dh = adObj.waiving.two.dh;
                        rwyObj.appr = adObj.waiving.two.appr;
                      }
                    } else {
                      if (adObj.waiving.two.rwy < 10) {
                        rwyObj.rwy = `0${adObj.waiving.two.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.waiving.two.rwy;
                      }
                      rwyObj.vis = adObj.waiving.two.vis;
                      rwyObj.da = adObj.waiving.two.da;
                      rwyObj.dh = adObj.waiving.two.dh;
                      rwyObj.appr = adObj.waiving.two.appr;
                    }
                  }
                }
                item.rwy.waiving = rwyObj;
              }
            }
          });
        } else if (REG_VRB.test(item.wind) || REG_VRB_GUST.test(item.wind)) {
          adList.forEach((adObj) => {
            if (obj.ad === adObj.name) {
              if ("waiving" in adObj) {
                if (adObj.waiving.one.dh <= adObj.waiving.two.dh) {
                  if (oneFms && adObj.waiving.one.appr.includes("LPV")) {
                    if (adObj.waiving.oneFms.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.waiving.oneFms.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.waiving.oneFms.one.rwy;
                    }
                    rwyObj.vis = adObj.waiving.oneFms.one.vis;
                    rwyObj.da = adObj.waiving.oneFms.one.da;
                    rwyObj.dh = adObj.waiving.oneFms.one.dh;
                    rwyObj.appr = adObj.waiving.oneFms.one.appr;
                  } else {
                    if (adObj.waiving.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.waiving.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.waiving.one.rwy;
                    }
                    rwyObj.vis = adObj.waiving.one.vis;
                    rwyObj.da = adObj.waiving.one.da;
                    rwyObj.dh = adObj.waiving.one.dh;
                    rwyObj.appr = adObj.waiving.one.appr;
                  }
                } else {
                  if (oneFms && adObj.waiving.two.appr.includes("LPV")) {
                    if (adObj.waiving.oneFms.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.waiving.oneFms.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.waiving.oneFms.two.rwy;
                    }
                    rwyObj.vis = adObj.waiving.oneFms.two.vis;
                    rwyObj.da = adObj.waiving.oneFms.two.da;
                    rwyObj.dh = adObj.waiving.oneFms.two.dh;
                    rwyObj.appr = adObj.waiving.oneFms.two.appr;
                  } else {
                    if (adObj.waiving.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.waiving.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.waiving.two.rwy;
                    }
                    rwyObj.vis = adObj.waiving.two.vis;
                    rwyObj.da = adObj.waiving.two.da;
                    rwyObj.dh = adObj.waiving.two.dh;
                    rwyObj.appr = adObj.waiving.two.appr;
                  }
                }
                item.rwy.waiving = rwyObj;
              }
            }
          });
        }
      } else {
        adList.forEach((adObj) => {
          if (obj.ad === adObj.name) {
            if (i !== 0) {
              if ("waiving" in obj.sort.content[i - 1].rwy) {
                item.rwy.waiving = obj.sort.content[i - 1].rwy.waiving;
              }
            }
          }
        });
      }
    });
  }
  return obj;
};

const tafHeadwind = (windDir, windStrength, rwy) => {
  let rwyDir = rwy * 10;
  var difference = windDir - rwyDir;
  if (difference > 180) {
    difference = 360 - difference;
  }
  let radians = (difference * Math.PI) / 180;
  let headWind = windStrength * Math.cos(radians);
  return headWind;
};

const rwyInUseTaf = (objIn, oneFmsIn) => {
  const obj = { ...objIn };
  const oneFms = oneFmsIn;
  let rwyObj = {};
  let tempWind = 0;
  let tempRwyDir = 0;
  if ("sort" in obj) {
    obj.sort.content.forEach((item, i) => {
      if ("wind" in item) {
        rwyObj = {};
        if (REG_WIND.test(item.wind) || REG_GUST.test(item.wind)) {
          adList.forEach((adObj) => {
            if (obj.ad === adObj.name) {
              let windDir = undefined;
              let windVel = undefined;
              let florøAcceptableTailwind = false;
              tempWind = item.wind.slice(0, 3);
              tempRwyDir = adObj.one.rwy * 10;

              if (REG_WIND.test(item.wind)) {
                windDir = tempWind;
                windVel = item.wind.slice(3, 5);
              } else {
                windDir = tempWind;
                windVel = item.wind.slice(6, 8);
              }

              if (windDir !== undefined && windVel !== undefined) {
                const headwind = tafHeadwind(windDir, windVel, adObj.one.rwy);
                if (headwind <= 20) {
                  florøAcceptableTailwind = true;
                }
              }

              const difference = Math.abs(tempWind - tempRwyDir);
              if (difference < 90 || difference > 270) {
                if (oneFms && adObj.one.appr.includes("LPV")) {
                  if (adObj.oneFms.one.rwy < 10) {
                    rwyObj.rwy = `0${adObj.oneFms.one.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.oneFms.one.rwy;
                  }
                  const headwind = tafHeadwind(
                    windDir,
                    windVel,
                    adObj.oneFms.one.rwy
                  );
                  rwyObj.headwind = headwind;
                  rwyObj.vis = adObj.oneFms.one.vis;
                  rwyObj.da = adObj.oneFms.one.da;
                  rwyObj.dh = adObj.oneFms.one.dh;
                  rwyObj.appr = adObj.oneFms.one.appr;
                } else {
                  if (adObj.one.rwy < 10) {
                    rwyObj.rwy = `0${adObj.one.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.one.rwy;
                  }
                  const headwind = tafHeadwind(windDir, windVel, adObj.one.rwy);
                  rwyObj.headwind = headwind;
                  rwyObj.vis = adObj.one.vis;
                  rwyObj.da = adObj.one.da;
                  rwyObj.dh = adObj.one.dh;
                  rwyObj.appr = adObj.one.appr;
                }
                if (REG_WIND.test(item.wind)) {
                  rwyObj.windDir = tempWind;
                  rwyObj.windStrength = item.wind.slice(3, 5);
                } else {
                  rwyObj.windDir = tempWind;
                  rwyObj.windStrength = item.wind.slice(6, 8);
                }
              } else if (difference === 90 || difference === 270) {
                if (adObj.one.dh <= adObj.two.dh) {
                  if (oneFms && adObj.one.appr.includes("LPV")) {
                    if (adObj.oneFms.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.oneFms.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.oneFms.one.rwy;
                    }
                    const headwind = tafHeadwind(
                      windDir,
                      windVel,
                      adObj.oneFms.one.rwy
                    );
                    rwyObj.headwind = headwind;
                    rwyObj.vis = adObj.oneFms.one.vis;
                    rwyObj.da = adObj.oneFms.one.da;
                    rwyObj.dh = adObj.oneFms.one.dh;
                    rwyObj.appr = adObj.oneFms.one.appr;
                  } else {
                    if (adObj.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.one.rwy;
                    }
                    const headwind = tafHeadwind(
                      windDir,
                      windVel,
                      adObj.one.rwy
                    );
                    rwyObj.headwind = headwind;
                    rwyObj.vis = adObj.one.vis;
                    rwyObj.da = adObj.one.da;
                    rwyObj.dh = adObj.one.dh;
                    rwyObj.appr = adObj.one.appr;
                  }
                  if (REG_WIND.test(item.wind)) {
                    rwyObj.windDir = tempWind;
                    rwyObj.windStrength = item.wind.slice(3, 5);
                  } else {
                    rwyObj.windDir = tempWind;
                    rwyObj.windStrength = item.wind.slice(6, 8);
                  }
                } else {
                  if (oneFms && adObj.two.appr.includes("LPV")) {
                    if (adObj.oneFms.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.oneFms.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.oneFms.two.rwy;
                    }
                    const headwind = tafHeadwind(
                      windDir,
                      windVel,
                      adObj.oneFms.two.rwy
                    );
                    rwyObj.headwind = headwind;
                    rwyObj.vis = adObj.oneFms.two.vis;
                    rwyObj.da = adObj.oneFms.two.da;
                    rwyObj.dh = adObj.oneFms.two.dh;
                    rwyObj.appr = adObj.oneFms.two.appr;
                  } else {
                    if (adObj.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.two.rwy;
                    }
                    const headwind = tafHeadwind(
                      windDir,
                      windVel,
                      adObj.two.rwy
                    );
                    rwyObj.headwind = headwind;
                    rwyObj.vis = adObj.two.vis;
                    rwyObj.da = adObj.two.da;
                    rwyObj.dh = adObj.two.dh;
                    rwyObj.appr = adObj.two.appr;
                  }
                  if (REG_WIND.test(item.wind)) {
                    rwyObj.windDir = tempWind;
                    rwyObj.windStrength = item.wind.slice(3, 5);
                  } else {
                    rwyObj.windDir = tempWind;
                    rwyObj.windStrength = item.wind.slice(6, 8);
                  }
                }
                item.rwy = rwyObj;
              } else {
                if (oneFms && adObj.two.appr.includes("LPV")) {
                  if (adObj.name === "ENFL") {
                    if (florøAcceptableTailwind) {
                      if (adObj.oneFms.one.rwy < 10) {
                        rwyObj.rwy = `0${adObj.oneFms.one.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.oneFms.one.rwy;
                      }
                      const headwind = tafHeadwind(
                        windDir,
                        windVel,
                        adObj.oneFms.one.rwy
                      );
                      rwyObj.headwind = headwind;
                      rwyObj.vis = adObj.oneFms.one.vis;
                      rwyObj.da = adObj.oneFms.one.da;
                      rwyObj.dh = adObj.oneFms.one.dh;
                      rwyObj.appr = adObj.oneFms.one.appr;
                    } else {
                      if (adObj.oneFms.two.rwy < 10) {
                        rwyObj.rwy = `0${adObj.oneFms.two.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.oneFms.two.rwy;
                      }
                      const headwind = tafHeadwind(
                        windDir,
                        windVel,
                        adObj.oneFms.two.rwy
                      );
                      rwyObj.headwind = headwind;
                      rwyObj.vis = adObj.oneFms.two.vis;
                      rwyObj.da = adObj.oneFms.two.da;
                      rwyObj.dh = adObj.oneFms.two.dh;
                      rwyObj.appr = adObj.oneFms.two.appr;
                    }
                  } else {
                    if (adObj.oneFms.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.oneFms.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.oneFms.two.rwy;
                    }
                    const headwind = tafHeadwind(
                      windDir,
                      windVel,
                      adObj.oneFms.two.rwy
                    );
                    rwyObj.headwind = headwind;
                    rwyObj.vis = adObj.oneFms.two.vis;
                    rwyObj.da = adObj.oneFms.two.da;
                    rwyObj.dh = adObj.oneFms.two.dh;
                    rwyObj.appr = adObj.oneFms.two.appr;
                  }
                } else {
                  if (adObj.name === "ENFL") {
                    if (florøAcceptableTailwind) {
                      if (adObj.one.rwy < 10) {
                        rwyObj.rwy = `0${adObj.one.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.one.rwy;
                      }
                      const headwind = tafHeadwind(
                        windDir,
                        windVel,
                        adObj.one.rwy
                      );
                      rwyObj.headwind = headwind;
                      rwyObj.vis = adObj.one.vis;
                      rwyObj.da = adObj.one.da;
                      rwyObj.dh = adObj.one.dh;
                      rwyObj.appr = adObj.one.appr;
                    } else {
                      if (adObj.two.rwy < 10) {
                        rwyObj.rwy = `0${adObj.two.rwy}`;
                      } else {
                        rwyObj.rwy = adObj.two.rwy;
                      }
                      const headwind = tafHeadwind(
                        windDir,
                        windVel,
                        adObj.two.rwy
                      );
                      rwyObj.headwind = headwind;
                      rwyObj.vis = adObj.two.vis;
                      rwyObj.da = adObj.two.da;
                      rwyObj.dh = adObj.two.dh;
                      rwyObj.appr = adObj.two.appr;
                    }
                  } else {
                    if (adObj.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.two.rwy;
                    }
                    const headwind = tafHeadwind(
                      windDir,
                      windVel,
                      adObj.two.rwy
                    );
                    rwyObj.headwind = headwind;
                    rwyObj.vis = adObj.two.vis;
                    rwyObj.da = adObj.two.da;
                    rwyObj.dh = adObj.two.dh;
                    rwyObj.appr = adObj.two.appr;
                  }
                }
                if (REG_WIND.test(item.wind)) {
                  rwyObj.windDir = tempWind;
                  rwyObj.windStrength = item.wind.slice(3, 5);
                } else {
                  rwyObj.windDir = tempWind;
                  rwyObj.windStrength = item.wind.slice(6, 8);
                }
              }
              item.rwy = rwyObj;
            }
          });
          rigList.forEach((rigObj) => {
            if (obj.ad === rigObj.name) {
              rwyObj.deckHdg = rigObj.deckHdg;
              rwyObj.deckHeight = rigObj.deckHeight;
              rwyObj.fixedRig = rigObj.fixedRig;
              rwyObj.id = rigObj.id;
              item.rig = rwyObj;
            }
          });
        } else if (REG_VRB.test(item.wind) || REG_VRB_GUST.test(item.wind)) {
          adList.forEach((adObj) => {
            if (obj.ad === adObj.name) {
              if (adObj.one.dh <= adObj.two.dh) {
                if (oneFms && adObj.one.appr.includes("LPV")) {
                  if (adObj.oneFms.one.rwy < 10) {
                    rwyObj.rwy = `0${adObj.oneFms.one.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.oneFms.one.rwy;
                  }
                  rwyObj.vis = adObj.oneFms.one.vis;
                  rwyObj.da = adObj.oneFms.one.da;
                  rwyObj.dh = adObj.oneFms.one.dh;
                  rwyObj.appr = adObj.oneFms.one.appr;
                } else {
                  if (adObj.one.rwy < 10) {
                    rwyObj.rwy = `0${adObj.one.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.one.rwy;
                  }
                  rwyObj.vis = adObj.one.vis;
                  rwyObj.da = adObj.one.da;
                  rwyObj.dh = adObj.one.dh;
                  rwyObj.appr = adObj.one.appr;
                }
                rwyObj.windDir = 0;
                if (REG_VRB.test(item.wind)) {
                  rwyObj.windStrength = item.wind.slice(4, 5);
                  rwyObj.headwind = item.wind.slice(4, 5);
                } else {
                  rwyObj.windStrength = item.wind.slice(6, 8);
                  rwyObj.headwind = item.wind.slice(6, 8);
                }
                rwyObj.windVar = true;
              } else {
                if (oneFms && adObj.two.appr.includes("LPV")) {
                  if (adObj.oneFms.two.rwy < 10) {
                    rwyObj.rwy = `0${adObj.oneFms.two.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.oneFms.two.rwy;
                  }
                  rwyObj.vis = adObj.oneFms.two.vis;
                  rwyObj.da = adObj.oneFms.two.da;
                  rwyObj.dh = adObj.oneFms.two.dh;
                  rwyObj.appr = adObj.oneFms.two.appr;
                } else {
                  if (adObj.two.rwy < 10) {
                    rwyObj.rwy = `0${adObj.two.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.two.rwy;
                  }
                  rwyObj.vis = adObj.two.vis;
                  rwyObj.da = adObj.two.da;
                  rwyObj.dh = adObj.two.dh;
                  rwyObj.appr = adObj.two.appr;
                }
                rwyObj.windDir = 0;
                if (REG_VRB.test(item.wind)) {
                  rwyObj.windStrength = item.wind.slice(4, 5);
                  rwyObj.headwind = item.wind.slice(4, 5);
                } else {
                  rwyObj.windStrength = item.wind.slice(6, 8);
                  rwyObj.headwind = item.wind.slice(6, 8);
                }
                rwyObj.windVar = true;
              }
              item.rwy = rwyObj;
            }
          });
          rigList.forEach((rigObj) => {
            if (obj.ad === rigObj.name) {
              rwyObj.deckHdg = rigObj.deckHdg;
              rwyObj.deckHeight = rigObj.deckHeight;
              rwyObj.fixedRig = rigObj.fixedRig;
              rwyObj.id = rigObj.id;
              item.rig = rwyObj;
            }
          });
        }
      } else {
        rigList.forEach((rigObj) => {
          if (obj.ad === rigObj.name) {
            rwyObj.deckHdg = rigObj.deckHdg;
            rwyObj.deckHeight = rigObj.deckHeight;
            rwyObj.fixedRig = rigObj.fixedRig;
            rwyObj.id = rigObj.id;
            item.rig = rwyObj;
          }
        });
        adList.forEach((adObj) => {
          if (obj.ad === adObj.name) {
            if (i !== 0) {
              item.rwy = obj.sort.content[i - 1].rwy;
            }
          }
        });
      }
    });
  }
  return obj;
};

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

const crosswindTaf = (objIn) => {
  const obj = { ...objIn };
  if ("sort" in obj) {
    obj.sort.content.forEach((item) => {
      if ("rwy" in item) {
        if ("windVar" in item.rwy) {
          item.rwy.crossWind = item.rwy.windStrength;
        } else {
          const rwyDir = item.rwy.rwy * 10;
          let difference = Math.abs(parseInt(item.rwy.windDir, 10) - rwyDir);
          if (difference > 180) {
            difference = 360 - difference;
          }
          const xwc = Math.sin(toRadians(difference));
          item.rwy.crossWind = Math.ceil(xwc * item.rwy.windStrength);
        }
      }
    });
  }
  return obj;
};

const setRigRegex = (objIn) => {
  const obj = { ...objIn };
  const regexObj = {};

  regexObj.minVisDay = 800;
  regexObj.minVisNight = 1200;
  regexObj.enrouteVisDay = 4000;
  regexObj.enrouteVisNight = 5000;
  regexObj.enrouteCldDay = new RegExp(`^\\D{3}00[0-5]\\D{0,2}\\D?$`);
  regexObj.enrouteCldNight = new RegExp(
    `^\\D{3}00[0-9]\\D{0,2}\\D?$|^\\D{3}01[0-1]\\D{0,2}\\D?$`
  );
  regexObj.enrouteVvDay = /^VV00[0-5]$/s;
  regexObj.enrouteVvNight = new RegExp(`^VV00[0-9]$|^VV01[0-1]$`);
  regexObj.regVis = new RegExp(`^\\d{4}$`);
  regexObj.cavok = new RegExp(`^CAVOK$`);
  regexObj.ts = new RegExp(`TS`);

  const deckBase =
    (obj.deckHeight + 50) % 100 === 0
      ? Math.floor((obj.deckHeight + 50) / 100) - 1
      : Math.floor((obj.deckHeight + 50) / 100);

  const minDayBase =
    deckBase < 2
      ? new RegExp(`^(BKN|OVC)00[0-1]\\D{0,2}\\D?$`)
      : new RegExp(`^(BKN|OVC)00[0-${deckBase}]\\D{0,2}\\D?$`);

  const minDayVv =
    deckBase < 2
      ? new RegExp(`^VV00[0-1]$`)
      : new RegExp(`^VV00[0-${deckBase}]$`);

  const minNightBase =
    deckBase < 3
      ? new RegExp(`^(BKN|OVC)00[0-2]\\D{0,2}\\D?$`)
      : new RegExp(`^(BKN|OVC)00[0-${deckBase}]\\D{0,2}\\D?$`);

  const minNightVv =
    deckBase < 3
      ? new RegExp(`^VV00[0-2]$`)
      : new RegExp(`^VV00[0-${deckBase}]$`);

  regexObj.minCldDay = minDayBase;
  regexObj.minCldNight = minNightBase;
  regexObj.minVvDay = minDayVv;
  regexObj.minVvNight = minNightVv;

  return regexObj;
};

const setRegex = (objIn) => {
  const obj = { ...objIn };
  const regexObj = {};

  let numOne;
  let numTwo;
  let minOne;
  let minTwo;
  let altOne;
  let altTwo;
  let secWaveOne;
  let secWaveTwo;
  let thirdWaveOne;
  let thirdWaveTwo;
  let thirdWaveOneSecondAirport;
  let thirdWaveTwoSecondAirport;
  regexObj.vis = obj.vis;
  regexObj.altVis = obj.vis + 400;
  regexObj.wavingVisDay = 4000;
  regexObj.secondWaivingVis = 2500;
  regexObj.thirdWaivingVis = 3000;
  regexObj.wavingVisNight = 5000;
  regexObj.regVis = new RegExp(`^\\d{4}$`);
  regexObj.cavok = new RegExp(`^CAVOK$`);
  regexObj.ts = new RegExp(`TS`);

  if (!isNaN(obj.crossWind) && obj.crossWind > 35) {
    regexObj.tooHighCrosswind = true;
  } else {
    regexObj.tooHighCrosswind = false;
  }

  const wavingBase =
    obj.dh % 100 === 0
      ? Math.floor((obj.dh + 400) / 100) - 1
      : Math.floor((obj.dh + 400) / 100);
  const secondWavingBase =
    obj.dh % 100 === 0
      ? Math.floor((obj.dh + 700) / 100) - 1
      : Math.floor((obj.dh + 700) / 100);
  const thirdWavingBase =
    obj.dh % 100 === 0
      ? Math.floor((obj.dh + 400) / 100) - 1
      : Math.floor((obj.dh + 400) / 100);
  let thirdWavingBaseSecondAirport = 0;
  if ("waiving" in obj) {
    thirdWavingBaseSecondAirport =
      obj.waiving.dh % 100 === 0
        ? Math.floor((obj.waiving.dh + 400) / 100) - 1
        : Math.floor((obj.waiving.dh + 400) / 100);
  }
  const minima =
    obj.dh % 100 === 0
      ? Math.floor(obj.dh / 100) - 1
      : Math.floor(obj.dh / 100);
  const alt =
    obj.dh % 100 === 0
      ? Math.floor((obj.dh + 200) / 100) - 1
      : Math.floor((obj.dh + 200) / 100);
  if (secondWavingBase > 9) {
    secWaveOne = String(secondWavingBase).slice(0, 1);
    secWaveTwo = String(secondWavingBase).slice(1);
  }
  if (thirdWavingBase > 9) {
    thirdWaveOne = String(thirdWavingBase).slice(0, 1);
    thirdWaveTwo = String(thirdWavingBase).slice(1);
  }
  if ("waiving" in obj) {
    if (thirdWavingBaseSecondAirport > 9) {
      thirdWaveOneSecondAirport = String(thirdWavingBaseSecondAirport).slice(
        0,
        1
      );
      thirdWaveTwoSecondAirport = String(thirdWavingBaseSecondAirport).slice(1);
    }
  }
  if (secondWavingBase <= 9) {
    regexObj.secondWaivingCld = new RegExp(`^(BKN|OVC)00[0-9]\\D{0,2}\\D?$`);
    regexObj.secondWaivingVv = /^VV00[0-9]$/s;
  } else {
    regexObj.secondWaivingCld = new RegExp(
      `^(BKN|OVC)0[0-${
        secWaveOne - 1
      }][0-9]\\D{0,2}\\D?$|^(BKN|OVC)0${secWaveOne}[0-${secWaveTwo}]\\D{0,2}\\D?$`
    );
    regexObj.secondWaivingVv = new RegExp(
      `^VV0[0-${secWaveOne - 1}][0-9]$|^VV0${secWaveOne}[0-${secWaveTwo}]$`
    );
  }
  if (thirdWavingBase < 10) {
    regexObj.thirdWaivingCld = new RegExp(
      `^(BKN|OVC)00[0-${thirdWavingBase}]\\D{0,2}\\D?$`
    );
    regexObj.thirdWaivingVv = new RegExp(`^VV00[0-${thirdWavingBase}]$`);
  } else {
    regexObj.thirdWaivingCld = new RegExp(
      `^(BKN|OVC)0[0-${
        thirdWaveOne - 1
      }][0-9]\\D{0,2}\\D?$|^(BKN|OVC)0${thirdWaveOne}[0-${thirdWaveTwo}]\\D{0,2}\\D?$`
    );
    regexObj.thirdWaivingVv = new RegExp(
      `^VV0[0-${
        thirdWaveOne - 1
      }][0-9]$|^VV0${thirdWaveOne}[0-${thirdWaveTwo}]$`
    );
  }
  if (thirdWavingBaseSecondAirport < 10) {
    if (thirdWavingBaseSecondAirport === 0) {
      regexObj.thirdWaivingCldSecondAirport = new RegExp(
        `^(FEW|SCT|BKN|OVC)\\d\\d\\d\\D{0,2}\\D?$`
      );
      regexObj.thirdWaivingVvSecondAirport = new RegExp(`^VV\\d\\d\\d$`);
    } else {
      regexObj.thirdWaivingCldSecondAirport = new RegExp(
        `^(BKN|OVC)00[0-${thirdWavingBaseSecondAirport}]\\D{0,2}\\D?$`
      );
      regexObj.thirdWaivingVvSecondAirport = new RegExp(
        `^VV00[0-${thirdWavingBaseSecondAirport}]$`
      );
    }
  } else {
    regexObj.thirdWaivingCldSecondAirport = new RegExp(
      `^(BKN|OVC)0[0-${
        thirdWaveOneSecondAirport - 1
      }][0-9]\\D{0,2}\\D?$|^(BKN|OVC)0${thirdWaveOneSecondAirport}[0-${thirdWaveTwoSecondAirport}]\\D{0,2}\\D?$`
    );
    regexObj.thirdWaivingVvSecondAirport = new RegExp(
      `^VV0[0-${
        thirdWaveOneSecondAirport - 1
      }][0-9]$|^VV0${thirdWaveOneSecondAirport}[0-${thirdWaveTwoSecondAirport}]$`
    );
  }

  if (alt > 9) {
    altOne = String(alt).slice(0, 1);
    altTwo = String(alt).slice(1);
  }

  if (alt < 10) {
    regexObj.altCld = new RegExp(`^(BKN|OVC)00[0-${alt}]\\D{0,2}\\D?$`);
    regexObj.altVv = new RegExp(`^VV00[0-${alt}]$`);
  } else {
    regexObj.altCld = new RegExp(
      `^(BKN|OVC)0[0-${
        alt - 1
      }][0-9]\\D{0,2}\\D?$|^(BKN|OVC)0${altOne}[0-${altTwo}]\\D{0,2}\\D?$`
    );
    regexObj.altVv = new RegExp(
      `^VV0[0-${alt - 1}][0-9]$|^VV0${altOne}[0-${altTwo}]$`
    );
  }

  if (minima > 9) {
    minOne = String(minima).slice(0, 1);
    minTwo = String(minima).slice(1);
  }

  if (minima < 10) {
    regexObj.minimaCld = new RegExp(`^(BKN|OVC)00[0-${minima}]\\D{0,2}\\D?$`);
    regexObj.minimaVv = new RegExp(`^VV00[0-${minima}]$`);
  } else {
    regexObj.minimaCld = new RegExp(
      `^(BKN|OVC)0[0-${
        minima - 1
      }][0-9]\\D{0,2}\\D?$|^(BKN|OVC)0${minOne}[0-${minTwo}]\\D{0,2}\\D?$`
    );
    regexObj.minimaVv = new RegExp(
      `^VV0[0-${minima - 1}][0-9]$|^VV0${minOne}[0-${minTwo}]$`
    );
  }

  if (wavingBase > 9) {
    numOne = String(wavingBase).slice(0, 1);
    numTwo = String(wavingBase).slice(1);
  }

  regexObj.wavingCldNight = new RegExp(`^\\D{3}00[0-9]\\D{0,2}\\D?$`);
  regexObj.wavingVvNight = /^VV00[0-9]$/s;

  if (wavingBase < 10) {
    regexObj.wavingCldDay = new RegExp(
      `^\\D{3}00[0-${wavingBase}]\\D{0,2}\\D?$`
    );
    regexObj.wavingVvDay = new RegExp(`^VV00[0-${wavingBase}]$`);
  } else {
    regexObj.wavingCldDay = new RegExp(
      `^\\D{3}0[0-${
        numOne - 1
      }][0-9]\\D{0,2}\\D?$|^\\D{3}0${numOne}[0-${numTwo}]\\D{0,2}\\D?$`
    );
    regexObj.wavingVvDay = new RegExp(
      `^VV0[0-${numOne - 1}][0-9]$|^VV0${numOne}[0-${numTwo}]$`
    );
  }
  regexObj.ceil = wavingBase;
  regexObj.dh = obj.dh;

  return regexObj;
};

const setColor = (objIn) => {
  const obj = { ...objIn };
  if ("sort" in obj) {
    obj.sort.content.forEach((item) => {
      if ("rwy" in item) {
        if (item.status.tooHighCrosswind.tooHighCrosswind) {
          item.status.color.red = true;
        }
        if (
          item.rwy.appr.includes("LPV CAT 1") ||
          item.rwy.appr.includes("ILS")
        ) {
          if (item.status.belowMinima.vis === true) {
            item.status.color.red = true;
          }
        } else {
          if (
            item.status.belowMinima.vis === true ||
            item.status.belowMinima.cld === true
          ) {
            item.status.color.red = true;
          }
        }
        if (
          item.status.belowWaveDay.vis === true ||
          item.status.belowWaveDay.cld === true
        ) {
          item.status.color.dayYellow = true;
        }
        if (
          item.status.belowSecondWave.vis === true ||
          item.status.belowSecondWave.cld === true
        ) {
          item.status.color.secondDayYellow = true;
        }
        if (
          item.status.belowThirdWave.vis === true ||
          item.status.belowThirdWave.cld === true
        ) {
          item.status.color.thirdDayYellow = true;
        }
        if (
          item.status.belowThirdWaveSecondAirport.vis === true ||
          item.status.belowThirdWaveSecondAirport.cld === true
        ) {
          item.status.color.thirdDaySecondAirportYellow = true;
        }
        if (
          item.status.belowWaveNight.vis === true ||
          item.status.belowWaveNight.cld === true
        ) {
          item.status.color.nightYellow = true;
        }
        if (
          item.status.belowAlternate.vis === true ||
          item.status.belowAlternate.cld === true
        ) {
          item.status.color.blue = true;
        }
        if (
          item.status.color.red === false &&
          item.status.color.secondDayYellow === false &&
          item.status.color.thirdDayYellow === false &&
          item.status.color.thirdDaySecondAirportYellow === false &&
          item.status.color.blue === false
        ) {
          item.status.color.nightGreen = true;
        }
        if (
          item.status.color.red === false &&
          item.status.color.dayYellow === false &&
          item.status.color.secondDayYellow === false &&
          item.status.color.thirdDayYellow === false &&
          item.status.color.thirdDaySecondAirportYellow === false &&
          item.status.color.blue === false
        ) {
          item.status.color.dayGreen = true;
        }
      }
      if ("rig" in item) {
        if (
          item.status.belowEnrouteDay.vis === true ||
          item.status.belowEnrouteDay.cld === true
        ) {
          item.status.color.enrouteDayYellow = true;
        }
        if (
          item.status.belowEnrouteNight.vis === true ||
          item.status.belowEnrouteNight.cld === true
        ) {
          item.status.color.enrouteNightYellow = true;
        }
        if (
          item.status.belowMinimaDay.vis === true ||
          item.status.belowMinimaDay.cld === true
        ) {
          item.status.color.dayRed = true;
        }
        if (
          item.status.belowMinimaNight.vis === true ||
          item.status.belowMinimaNight.cld === true
        ) {
          item.status.color.nightRed = true;
        }
        if (
          item.status.color.dayRed === false &&
          item.status.color.enrouteDayYellow === false
        ) {
          item.status.color.dayGreen = true;
        }
        if (
          item.status.color.nightRed === false &&
          item.status.color.enrouteNightYellow === false
        ) {
          item.status.color.nightGreen = true;
        }
      }
    });
  }
  return obj;
};

const statusBasedOnRegex = (objIn) => {
  const obj = { ...objIn };
  if ("sort" in obj) {
    obj.sort.content.forEach((item) => {
      if ("rwy" in item) {
        const regexObj = setRegex(item.rwy);
        const status = {
          ts: {
            ts: false,
          },
          tooHighCrosswind: {
            tooHighCrosswind: false,
          },
          belowWaveDay: {
            cld: false,
            vis: false,
          },
          belowWaveNight: {
            cld: false,
            vis: false,
          },
          belowSecondWave: {
            cld: false,
            vis: false,
          },
          belowThirdWave: {
            cld: false,
            vis: false,
          },
          belowThirdWaveSecondAirport: {
            cld: false,
            vis: false,
          },
          belowAlternate: {
            cld: false,
            vis: false,
          },
          belowMinima: {
            cld: false,
            vis: false,
          },
          color: {
            red: false,
            dayYellow: false,
            secondDayYellow: false,
            thirdDayYellow: false,
            thirdDaySecondAirportYellow: false,
            nightYellow: false,
            dayGreen: false,
            nightGreen: false,
            blue: false,
          },
        };
        if (regexObj.tooHighCrosswind) {
          status.tooHighCrosswind.tooHighCrosswind = true;
        }
        item.wx.forEach((wxItem) => {
          if (regexObj.ts.test(wxItem)) {
            status.ts.ts = true;
          }
          if (regexObj.regVis.test(wxItem)) {
            if (wxItem < regexObj.vis) {
              status.belowMinima.vis = true;
            }
            if (wxItem < regexObj.altVis) {
              status.belowAlternate.vis = true;
            }
            if (wxItem < regexObj.wavingVisDay) {
              status.belowWaveDay.vis = true;
            }
            if (wxItem < regexObj.secondWaivingVis) {
              status.belowSecondWave.vis = true;
            }
            if (wxItem < regexObj.thirdWaivingVis) {
              status.belowThirdWave.vis = true;
            }
          }
          if (regexObj.altCld.test(wxItem)) {
            status.belowAlternate.cld = true;
          }
          if (regexObj.altVv.test(wxItem)) {
            status.belowAlternate.cld = true;
          }
          if (regexObj.minimaCld.test(wxItem)) {
            status.belowMinima.cld = true;
          }
          if (regexObj.minimaVv.test(wxItem)) {
            status.belowMinima.cld = true;
          }
          if (regexObj.wavingCldDay.test(wxItem)) {
            status.belowWaveDay.cld = true;
          }
          if (regexObj.wavingVvDay.test(wxItem)) {
            status.belowWaveDay.cld = true;
          }
          if (regexObj.wavingCldNight.test(wxItem)) {
            status.belowWaveNight.cld = true;
          }
          if (regexObj.wavingVvNight.test(wxItem)) {
            status.belowWaveNight.cld = true;
          }
          if (regexObj.secondWaivingCld.test(wxItem)) {
            status.belowSecondWave.cld = true;
          }
          if (regexObj.secondWaivingVv.test(wxItem)) {
            status.belowSecondWave.cld = true;
          }
          if (regexObj.thirdWaivingCld.test(wxItem)) {
            status.belowThirdWave.cld = true;
          }
          if (regexObj.thirdWaivingVv.test(wxItem)) {
            status.belowThirdWave.cld = true;
          }
          if (regexObj.thirdWaivingCldSecondAirport.test(wxItem)) {
            status.belowThirdWaveSecondAirport.cld = true;
          } else if (!("waiving" in item.rwy)) {
            status.belowThirdWaveSecondAirport.cld = true;
          }
          if (regexObj.thirdWaivingVvSecondAirport.test(wxItem)) {
            status.belowThirdWaveSecondAirport.cld = true;
          } else if (!("waiving" in item.rwy)) {
            status.belowThirdWaveSecondAirport.cld = true;
          }
        });
        item.status = status;
      } else if ("rig" in item) {
        const regexObj = setRigRegex(item.rig);
        const status = {
          ts: {
            ts: false,
          },
          belowEnrouteDay: {
            cld: false,
            vis: false,
          },
          belowEnrouteNight: {
            cld: false,
            vis: false,
          },
          belowMinimaDay: {
            cld: false,
            vis: false,
          },
          belowMinimaNight: {
            cld: false,
            vis: false,
          },
          color: {
            dayRed: false,
            nightRed: false,
            enrouteDayYellow: false,
            enrouteNightYellow: false,
            dayGreen: false,
            nightGreen: false,
          },
        };
        item.wx.forEach((wxItem) => {
          if (regexObj.ts.test(wxItem)) {
            status.ts.ts = true;
          }
          if (regexObj.regVis.test(wxItem)) {
            if (wxItem < regexObj.minVisDay) {
              status.belowMinimaDay.vis = true;
            }
            if (wxItem < regexObj.enrouteVisDay) {
              status.belowEnrouteDay.vis = true;
            }
            if (wxItem < regexObj.minVisNight) {
              status.belowMinimaNight.vis = true;
            }
            if (wxItem < regexObj.enrouteVisNight) {
              status.belowEnrouteNight.vis = true;
            }
          }
          if (regexObj.enrouteCldDay.test(wxItem)) {
            status.belowEnrouteDay.cld = true;
          }
          if (regexObj.enrouteVvDay.test(wxItem)) {
            status.belowEnrouteDay.cld = true;
          }
          if (regexObj.minCldDay.test(wxItem)) {
            status.belowMinimaDay.cld = true;
          }
          if (regexObj.minVvDay.test(wxItem)) {
            status.belowMinimaDay.cld = true;
          }
          if (regexObj.enrouteCldNight.test(wxItem)) {
            status.belowEnrouteNight.cld = true;
          }
          if (regexObj.enrouteVvNight.test(wxItem)) {
            status.belowEnrouteNight.cld = true;
          }
          if (regexObj.minCldNight.test(wxItem)) {
            status.belowMinimaNight.cld = true;
          }
          if (regexObj.minVvNight.test(wxItem)) {
            status.belowMinimaNight.cld = true;
          }
        });
        item.status = status;
      }
    });
  }
  return obj;
};

const setColorForDisplay = (objIn, toggleDayNight) => {
  const colorNeutral = "white";
  const colorRed = "#cc172a";
  const colorYellow = "#ffcb35";
  const colorGreen = "#3bcc42";
  const obj = { ...objIn };
  // const items = [];
  // let typeOfLim = {}
  let color;
  // let id = 0;
  if ("sort" in obj) {
    obj.sort.content.forEach((wxItem) => {
      if (toggleDayNight) {
        if ("status" in wxItem) {
          // typeOfLim = wxItem.status.color
          if (wxItem.status.color.red) {
            // color = "redWx";
            color = colorRed;
          } else if (wxItem.status.color.dayRed) {
            // color = "redWx";
            color = colorRed;
          } else if (wxItem.status.color.enrouteDayYellow) {
            // color = "yellowWx";
            color = colorYellow;
          } else if (wxItem.status.color.dayYellow) {
            if (
              wxItem.status.color.secondDayYellow &&
              (wxItem.status.color.thirdDayYellow ||
                wxItem.status.color.thirdDaySecondAirportYellow)
            ) {
              // color = "yellowWx";
              color = colorYellow;
            } else {
              // color = "greenWx";
              color = colorGreen;
            }
          } else if (wxItem.status.color.secondDayYellow) {
            if (
              wxItem.status.color.dayYellow &&
              (wxItem.status.color.thirdDayYellow ||
                wxItem.status.color.thirdDaySecondAirportYellow)
            ) {
              // color = "yellowWx";
              color = colorYellow;
            } else {
              // color = "greenWx";
              color = colorGreen;
            }
          } else if (
            wxItem.status.color.thirdDayYellow ||
            wxItem.status.color.thirdDaySecondAirportYellow
          ) {
            if (
              wxItem.status.color.dayYellow &&
              wxItem.status.color.secondDayYellow
            ) {
              // color = "yellowWx";
              color = colorYellow;
            } else {
              // color = "greenWx";
              color = colorGreen;
            }
          } else if (wxItem.status.color.dayGreen) {
            // color = "greenWx";
            color = colorGreen;
          }
        }
      } else {
        if ("status" in wxItem) {
          // typeOfLim = wxItem.status.color
          if (wxItem.status.color.red) {
            // color = "redWx";
            color = colorRed;
          } else if (wxItem.status.color.nightRed) {
            // color = "redWx";
            color = colorRed;
          } else if (wxItem.status.color.enrouteNightYellow) {
            // color = "yellowWx";
            color = colorYellow;
          } else if (
            wxItem.status.color.thirdDayYellow ||
            wxItem.status.color.thirdDaySecondAirportYellow
          ) {
            if (wxItem.status.color.secondDayYellow) {
              // color = "yellowWx";
              color = colorYellow;
            } else {
              // color = "greenWx";
              color = colorGreen;
            }
          } else if (wxItem.status.color.secondDayYellow) {
            if (
              wxItem.status.color.thirdDayYellow ||
              wxItem.status.color.thirdDaySecondAirportYellow
            ) {
              // color = "yellowWx";
              color = colorYellow;
            } else {
              // color = "greenWx";
              color = colorGreen;
            }
          } else if (
            !wxItem.status.color.secondDayYellow &&
            !wxItem.status.color.thirdDayYellow
          ) {
            // color = "greenWx";
            color = colorGreen;
          }
        }
      }
      if (color) {
        wxItem.backgroundColor = color;
      } else {
        wxItem.backgroundColor = colorNeutral;
      }
    });
  }
  return obj;
};

export default function getTafRunwayInUse(objIn, oneFms, daytime) {
  const obj = { ...objIn };
  const wordArrayRes = stringToWordArray(obj);

  // if (obj.ad === "ENBV") {
  //   console.log("HER TESTES DET");
  //   const demo1 = stringToWordArray({
  //     id: 3,
  //     ad: "ENBV",
  //     type: null,
  //     data: "ENBV 150800Z 1509/1518 36020KT 9999 FEW020 BKN025 TEMPO 1510/1518 2000 SHSN VV008 TEMPO 1510/1518 SCT020CB TEMPO 1509/1515",
  //     time: {
  //       year: "2023",
  //       month: "11",
  //       date: "15",
  //       hour: "20",
  //       minute: "05",
  //     },
  //   });
  //   const demo2 = splitTafTimes(demo1);
  //   const demo4 = setInitDate(demo2);
  //   const demo5 = setTafStartEndTime(demo4);
  //   const demo6 = tafWind(demo5);
  //   const demo7 = rwyInUseTaf(demo6, oneFms);

  //   console.log(demo7);
  // }

  const tafTimes = splitTafTimes(wordArrayRes);
  const initDate = setInitDate(tafTimes);
  const tafStartEndTime = setTafStartEndTime(initDate);
  const tafWindRes = tafWind(tafStartEndTime);
  const rwyInUseTafRes = rwyInUseTaf(tafWindRes, oneFms);
  const addSecondApprForWaivingRes = addSecondApprForWaiving(
    rwyInUseTafRes,
    oneFms
  );
  const crosswindTafRes = crosswindTaf(addSecondApprForWaivingRes);
  const regexRes = statusBasedOnRegex(crosswindTafRes);
  const setColorRes = setColor(regexRes);
  const setColorForDisplayRes = setColorForDisplay(setColorRes, daytime);
  return setColorForDisplayRes;
}
