import React, { useContext, useState } from "react";
import { AdContext } from "../context/AdContext";
import { getPresetAd } from "../data/FetchFile";
import NavigationOutlinedIcon from "@mui/icons-material/NavigationOutlined";
import "./MainItem.css";
import TafItem from "./TafItem";
import Notam from "./Notam";
import Detail from "./Detail";
import RigInfoRow from "./RigInfoRow";

const MainItem = () => {
  const contextObj = useContext(AdContext);
  const metar = contextObj.metar[0];
  const metarOneFms = contextObj.metarOneFms[0];
  const taf = contextObj.taf[0];
  const tafNight = contextObj.tafNight[0];
  const tafOneFms = contextObj.tafOneFms[0];
  const tafOneFmsNight = contextObj.tafOneFmsNight[0];

  const localStorageBase = contextObj.localStorageBase[0];
  const presetAd = getPresetAd(localStorageBase);
  const daytime = contextObj.daytime[0];
  const oneFms = contextObj.oneFms[0];
  const showNotam = contextObj.showNotam[0];

  const [showDetail, setShowDetail] = useState(null);

  const handleClick = (id) => {
    if (showDetail === id) {
      setShowDetail(null);
    } else {
      setShowDetail(id);
    }
  };

  function rigOrAd(metarItem) {
    if ("rwy" in metarItem) {
      if ("rig" in metarItem.rwy) {
        if (metarItem.rwy.rig) {
          if (metarItem.windDetails.vel > 60) {
            return "red";
          }
          return "white";
        } else {
          if (metarItem.windDetails.vel > 40) {
            return "red";
          }
          return "white";
        }
      }
      return "white";
    }
    return "white";
  }

  const metarDetails = (metarItem) => {
    if ("rwy" in metarItem) {
      if ("rig" in metarItem.rwy) {
        if (metarItem.rwy.rig) {
          return (
            <p style={styles.rwyAndRig}>
              HDG: {metarItem.rwy.deckHdg} / Height: {metarItem.rwy.deckHeight}
            </p>
          );
        } else {
          return (
            <p style={styles.rwyAndRig}>
              Rwy {metarItem.rwy.rwy}({metarItem.rwy.appr} - {metarItem.rwy.da}
              '({metarItem.rwy.dh}')/{metarItem.rwy.vis}m) || x-wind{" "}
              {metarItem.rwy.crossWind}kt ||{" "}
              {"headWind" in metarItem.rwy && metarItem.rwy.headWind < 0
                ? "tailwind"
                : "headwind"}{" "}
              {Math.abs(Math.round(metarItem.rwy.headWind))}kt
            </p>
          );
        }
      }
    }
  };

  function windIcon(metarItem) {
    if ("windDetails" in metarItem) {
      if ("type" in metarItem.windDetails) {
        if (metarItem.windDetails.type === "normal") {
          return (
            <div style={styles.windIcon}>
              {metarItem.rwy.headWind < 0 ? (
                <p style={styles.headWind}>
                  TAILWIND APPR {Math.abs(Math.round(metarItem.rwy.headWind))}KT
                </p>
              ) : null}
              <NavigationOutlinedIcon
                style={{
                  transform: `rotate(${metarItem.windDetails.dir - 180}deg)`,
                  marginRight: 10,
                  color: rigOrAd(metarItem),
                }}
              />
              <p style={{ color: rigOrAd(metarItem) }}>
                {metarItem.windDetails.vel} KT
              </p>
            </div>
          );
        }
        if (metarItem.windDetails.type === "gust") {
          return (
            <div style={styles.windIcon}>
              {metarItem.rwy.headWind < 0 ? (
                <p style={styles.headWind}>
                  TAILWIND APPR {Math.abs(Math.round(metarItem.rwy.headWind))}KT
                </p>
              ) : null}
              <NavigationOutlinedIcon
                style={{
                  transform: `rotate(${metarItem.windDetails.dir - 180}deg)`,
                  marginRight: 10,
                  color: rigOrAd(metarItem),
                }}
              />
              <p style={{ color: rigOrAd(metarItem) }}>
                {metarItem.windDetails.normalWindIfGust} GUST{" "}
                {metarItem.windDetails.vel} KT
              </p>
            </div>
          );
        }
        if (metarItem.windDetails.type === "vrb") {
          return (
            <div style={styles.windIcon}>
              <p style={{ marginRight: 10, color: rigOrAd(metarItem) }}>VRB</p>
              <p style={{ color: rigOrAd(metarItem) }}>
                {metarItem.windDetails.vel} KT
              </p>
            </div>
          );
        }
      }
    } else {
      return (
        <div style={styles.windIcon}>
          <p>NO WIND DATA</p>
        </div>
      );
    }
  }

  if (metar) {
    if (presetAd !== undefined && presetAd.length !== 0) {
      const res = [];
      if (localStorageBase === "Ekofisk" || localStorageBase === "Statfjord") {
        res.push(<RigInfoRow base={localStorageBase} />);
      }
      presetAd.airports.forEach((ad) => {
        if (oneFms) {
          metarOneFms.forEach((metarItem) => {
            if (ad === metarItem.ad) {
              if (daytime) {
                if (tafOneFms) {
                  tafOneFms.forEach((tafItem) => {
                    if (metarItem.ad === tafItem.ad) {
                      res.push(
                        <div
                          onClick={() => handleClick(metarItem.id)}
                          key={metarItem.id}
                          className="div"
                          style={styles.div}
                        >
                          <div style={styles.masterHeader}>
                            <div style={styles.header}>
                              <h2 style={styles.h2}>{metarItem.ad}</h2>
                              {metarItem.type ? (
                                <p style={styles.type}>
                                  {metarItem.type.toUpperCase()} METAR
                                </p>
                              ) : null}
                              {tafItem.type ? (
                                <p style={styles.type}>
                                  {tafItem.type.toUpperCase()} TAF
                                </p>
                              ) : null}
                              {metarItem.ts ? (
                                <p style={styles.typeYellow}>TS in METAR</p>
                              ) : null}
                              {tafItem.ts ? (
                                <p style={styles.typeYellow}>TS in TAF</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 25 &&
                              metarItem.rwy.crossWind <= 35 ? (
                                <p style={styles.typeYellow}>HIGH CROSSWIND</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 35 ? (
                                <p style={styles.typeRed}>TOO HIGH CROSSWIND</p>
                              ) : null}
                            </div>
                            {windIcon(metarItem)}
                          </div>
                          {metarItem.data ? (
                            <div style={styles.p}>
                              <p style={styles.title}>METAR</p>{" "}
                              {metarItem.data === "No data available"
                                ? metarItem.data
                                : metarItem.data.slice(5)}
                            </div>
                          ) : null}
                          {/* {"rwy" in metarItem ? metarDetails(metarItem) : null} */}
                          <TafItem item={tafItem} daytime={daytime} />
                          {showDetail === metarItem.id ? (
                            <Detail metarItem={metarItem} tafItem={tafItem} />
                          ) : null}
                          {showNotam && <Notam airport={ad} />}
                        </div>
                      );
                    }
                  });
                }
              } else {
                if (tafOneFmsNight) {
                  tafOneFmsNight.forEach((tafItem) => {
                    if (metarItem.ad === tafItem.ad) {
                      res.push(
                        <div
                          onClick={() => handleClick(metarItem.id)}
                          key={metarItem.id}
                          className="div"
                          style={styles.div}
                        >
                          <div style={styles.masterHeader}>
                            <div style={styles.header}>
                              <h2 style={styles.h2}>{metarItem.ad}</h2>
                              {metarItem.type ? (
                                <p style={styles.type}>
                                  {metarItem.type.toUpperCase()} METAR
                                </p>
                              ) : null}
                              {tafItem.type ? (
                                <p style={styles.type}>
                                  {tafItem.type.toUpperCase()} TAF
                                </p>
                              ) : null}
                              {metarItem.ts ? (
                                <p style={styles.typeYellow}>TS in METAR</p>
                              ) : null}
                              {tafItem.ts ? (
                                <p style={styles.typeYellow}>TS in TAF</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 25 &&
                              metarItem.rwy.crossWind <= 35 ? (
                                <p style={styles.typeYellow}>HIGH CROSSWIND</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 35 ? (
                                <p style={styles.typeRed}>TOO HIGH CROSSWIND</p>
                              ) : null}
                            </div>
                            {windIcon(metarItem)}
                          </div>
                          {metarItem.data ? (
                            <div style={styles.p}>
                              <p style={styles.title}>METAR</p>{" "}
                              {metarItem.data === "No data available"
                                ? metarItem.data
                                : metarItem.data.slice(5)}
                            </div>
                          ) : null}
                          {/* {"rwy" in metarItem ? metarDetails(metarItem) : null} */}
                          <TafItem item={tafItem} daytime={daytime} />
                          {showDetail === metarItem.id ? (
                            <Detail metarItem={metarItem} tafItem={tafItem} />
                          ) : null}
                          {showNotam && <Notam airport={ad} />}
                        </div>
                      );
                    }
                  });
                }
              }
            }
          });
        } else {
          metar.forEach((metarItem) => {
            if (ad === metarItem.ad) {
              if (daytime) {
                if (taf) {
                  taf.forEach((tafItem) => {
                    if (metarItem.ad === tafItem.ad) {
                      res.push(
                        <div
                          onClick={() => handleClick(metarItem.id)}
                          key={metarItem.id}
                          className="div"
                          style={styles.div}
                        >
                          <div style={styles.masterHeader}>
                            <div style={styles.header}>
                              <h2 style={styles.h2}>{metarItem.ad}</h2>
                              {metarItem.type ? (
                                <p style={styles.type}>
                                  {metarItem.type.toUpperCase()} METAR
                                </p>
                              ) : null}
                              {tafItem.type ? (
                                <p style={styles.type}>
                                  {tafItem.type.toUpperCase()} TAF
                                </p>
                              ) : null}
                              {metarItem.ts ? (
                                <p style={styles.typeYellow}>TS in METAR</p>
                              ) : null}
                              {tafItem.ts ? (
                                <p style={styles.typeYellow}>TS in TAF</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 25 &&
                              metarItem.rwy.crossWind <= 35 ? (
                                <p style={styles.typeYellow}>HIGH CROSSWIND</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 35 ? (
                                <p style={styles.typeRed}>TOO HIGH CROSSWIND</p>
                              ) : null}
                            </div>
                            {windIcon(metarItem)}
                          </div>
                          {metarItem.data ? (
                            <div style={styles.p}>
                              <p style={styles.title}>METAR</p>{" "}
                              {metarItem.data === "No data available"
                                ? metarItem.data
                                : metarItem.data.slice(5)}
                            </div>
                          ) : null}
                          {/* {"rwy" in metarItem ? metarDetails(metarItem) : null} */}
                          <TafItem item={tafItem} daytime={daytime} />
                          {showDetail === metarItem.id ? (
                            <Detail metarItem={metarItem} tafItem={tafItem} />
                          ) : null}
                          {showNotam && <Notam airport={ad} />}
                        </div>
                      );
                    }
                  });
                }
              } else {
                if (tafNight) {
                  tafNight.forEach((tafItem) => {
                    if (metarItem.ad === tafItem.ad) {
                      res.push(
                        <div
                          onClick={() => handleClick(metarItem.id)}
                          key={metarItem.id}
                          className="div"
                          style={styles.div}
                        >
                          <div style={styles.masterHeader}>
                            <div style={styles.header}>
                              <h2 style={styles.h2}>{metarItem.ad}</h2>
                              {metarItem.type ? (
                                <p style={styles.type}>
                                  {metarItem.type.toUpperCase()} METAR
                                </p>
                              ) : null}
                              {tafItem.type ? (
                                <p style={styles.type}>
                                  {tafItem.type.toUpperCase()} TAF
                                </p>
                              ) : null}
                              {metarItem.ts ? (
                                <p style={styles.typeYellow}>TS in METAR</p>
                              ) : null}
                              {tafItem.ts ? (
                                <p style={styles.typeYellow}>TS in TAF</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 25 &&
                              metarItem.rwy.crossWind <= 35 ? (
                                <p style={styles.typeYellow}>HIGH CROSSWIND</p>
                              ) : null}
                              {"rwy" in metarItem &&
                              "crossWind" in metarItem.rwy &&
                              !isNaN(metarItem.rwy.crossWind) &&
                              metarItem.rwy.crossWind > 35 ? (
                                <p style={styles.typeRed}>TOO HIGH CROSSWIND</p>
                              ) : null}
                            </div>
                            {windIcon(metarItem)}
                          </div>
                          {metarItem.data ? (
                            <div style={styles.p}>
                              <p style={styles.title}>METAR</p>{" "}
                              {metarItem.data === "No data available"
                                ? metarItem.data
                                : metarItem.data.slice(5)}
                            </div>
                          ) : null}
                          {/* {"rwy" in metarItem ? metarDetails(metarItem) : null} */}
                          <TafItem item={tafItem} daytime={daytime} />
                          {showDetail === metarItem.id ? (
                            <Detail metarItem={metarItem} tafItem={tafItem} />
                          ) : null}
                          {showNotam && <Notam airport={ad} />}
                        </div>
                      );
                    }
                  });
                }
              }
            }
          });
        }
      });
      return res;
    }
    return "";
  }
};

const styles = {
  div: {
    display: "flex",
    flexDirection: "column",
    // paddingTop: 10,
    paddingBottom: 5,
    borderBottom: "solid 1px white",
  },
  masterHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: -8,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  windIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  h2: {
    margin: 0,
    marginRight: 10,
    paddingTop: 0,
    textAlign: "left",
  },
  p: {
    margin: 0,
    marginBottom: 0,
    textAlign: "left",
    fontSize: "0.9rem",
  },
  rwyAndRig: {
    margin: 0,
    marginBottom: 8,
    textAlign: "left",
    fontSize: "0.7rem",
  },
  type: {
    margin: 0,
    marginLeft: 10,
    padding: "2px 4px",
    border: "2px solid white",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "x-small",
    fontWeight: "bold",
  },
  typeYellow: {
    margin: 0,
    marginLeft: 10,
    padding: "2px 4px",
    border: "2px solid #ffcb35",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "x-small",
    fontWeight: "bold",
    color: "#ffcb35",
  },
  typeRed: {
    margin: 0,
    marginLeft: 10,
    padding: "2px 4px",
    border: "2px solid #cc172a",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "x-small",
    fontWeight: "bold",
    color: "#cc172a",
  },
  headWind: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    padding: "2px 4px",
    border: "2px solid #ffcb35",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "x-small",
    fontWeight: "bold",
    color: "#ffcb35",
  },
  title: {
    fontWeight: "bold",
    fontSize: "0.5rem",
    margin: 0,
    padding: 0,
  },
};

export default MainItem;
