import React, { useContext, useState } from "react";
import { AdContext } from "../context/AdContext";
import "../App.css";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import UpdateIcon from "@mui/icons-material/Update";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";
import LightModeIcon from "@mui/icons-material/LightMode";
import NightlightIcon from "@mui/icons-material/Nightlight";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PublicIcon from "@mui/icons-material/Public";
import Suncalc from "./Suncalc";
import TimeWeatherUpdated from "./TimeWeatherUpdated";

const Header = () => {
  const contextObj = useContext(AdContext);
  const timeExpired = contextObj.timeExpired[0];
  const [localStorageBase, setLocalStorageBase] = contextObj.localStorageBase;
  const [showMenu, setShowMenu] = useState(false);
  const [showBaseMenu, setShowBaseMenu] = useState(false);
  const setShowCharts = contextObj.showCharts[1];
  const [daytime, setDaytime] = contextObj.daytime;
  const [oneFms, setOneFms] = contextObj.oneFms;
  const setReload = contextObj.reload[1];
  const [reloadToggle, setReloadToggle] = contextObj.reloadToggle;
  const [showNotam, setShowNotam] = contextObj.showNotam;
  const [toggleAbout, setToggleAbout] = contextObj.toggleAbout;
  const [toggleAirportData, setToggleAirportData] =
    contextObj.toggleAirportData;

  const handleChange = (ad) => {
    setLocalStorageBase(ad);
    localStorage.setItem("base", ad);
    setShowBaseMenu(false);
  };

  return (
    <div
      style={{
        width: "100%",
        position: "-webkit - sticky",
        position: "sticky",
        top: 0,
        backgroundColor: "#000",
        zIndex: 10,
      }}
    >
      <div
        style={{
          width: "100%",
          position: "-webkit - sticky",
          position: "sticky",
          top: 0,
          backgroundColor: timeExpired ? "#cc172a66" : "#000",
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <div style={styles.div}>
          <div style={styles.div}>
            <h1
              className="Base-menu"
              onClick={() => setShowBaseMenu((prevState) => !prevState)}
            >
              {localStorageBase}
            </h1>
            <Suncalc />
          </div>
          <TimeWeatherUpdated />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <div className="Icon">
              <IconButton
                onClick={() => {
                  setReload((prevState) => !prevState);
                }}
                size="large"
                edge="start"
                color="inherit"
                aria-label="charts"
                sx={{ margin: 0 }}
              >
                <RefreshIcon />
              </IconButton>
            </div>
            <div className="Icon">
              <IconButton
                onClick={() => setShowCharts((prevState) => !prevState)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="charts"
                sx={{ margin: 0 }}
              >
                <MapIcon />
              </IconButton>
            </div>
            <div className="Icon">
              <IconButton
                onClick={() => setShowMenu((prevState) => !prevState)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ margin: 0 }}
              >
                <SettingsIcon />
              </IconButton>
            </div>
          </div>
        </div>
        {showBaseMenu ? (
          <div style={styles.leftMenu}>
            <p
              className="Base-menu-items"
              onClick={() => handleChange("Bergen")}
            >
              Bergen
            </p>
            <p
              className="Base-menu-items"
              onClick={() => handleChange("Florø")}
            >
              Florø
            </p>
            <p className="Base-menu-items" onClick={() => handleChange("Sola")}>
              Sola
            </p>
            <p
              className="Base-menu-items"
              onClick={() => handleChange("Ekofisk")}
            >
              Ekofisk
            </p>
            <p
              className="Base-menu-items"
              onClick={() => handleChange("Statfjord")}
            >
              Statfjord
            </p>
            <p
              className="Base-menu-items"
              onClick={() => handleChange("Hammerfest")}
            >
              Hammerfest
            </p>
          </div>
        ) : null}
        {showMenu ? (
          <div style={styles.rightMenu}>
            {daytime ? (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setDaytime(false);
                    localStorage.setItem("daytime", JSON.stringify(false));
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<LightModeIcon />}
                >
                  Day
                </Button>
              </div>
            ) : (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setDaytime(true);
                    localStorage.setItem("daytime", JSON.stringify(true));
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<NightlightIcon />}
                >
                  Night
                </Button>
              </div>
            )}
            {oneFms ? (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setOneFms(false);
                    localStorage.setItem("oneFms", JSON.stringify(false));
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<CropSquareIcon />}
                >
                  One FMS
                </Button>
              </div>
            ) : (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setOneFms(true);
                    localStorage.setItem("oneFms", JSON.stringify(true));
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<SplitscreenIcon />}
                >
                  Two FMS
                </Button>
              </div>
            )}
            {showNotam ? (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setShowNotam(false);
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<SubtitlesIcon />}
                >
                  Showing NOTAM's
                </Button>
              </div>
            ) : (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setShowNotam(true);
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<SubtitlesOffIcon />}
                >
                  NOTAM's hidden
                </Button>
              </div>
            )}
            {reloadToggle ? (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setReloadToggle(false);
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<UpdateIcon />}
                >
                  Auto reload on
                </Button>
              </div>
            ) : (
              <div className="Icon">
                <Button
                  sx={{ margin: 0, fontWeight: "bold" }}
                  onClick={() => {
                    setReloadToggle(true);
                    setShowMenu((prevState) => !prevState);
                  }}
                  color="inherit"
                  variant="text"
                  endIcon={<UpdateDisabledIcon />}
                >
                  Auto reload off
                </Button>
              </div>
            )}
            <div className="Icon">
              <Button
                sx={{ margin: 0, fontWeight: "bold" }}
                onClick={() => {
                  setToggleAirportData((prevState) => !prevState);
                  if (toggleAbout) {
                    setToggleAbout(false);
                  }
                  setShowMenu((prevState) => !prevState);
                }}
                color="inherit"
                variant="text"
                endIcon={<PublicIcon />}
              >
                Airports and Rigs
              </Button>
            </div>
            <div className="Icon">
              <Button
                sx={{ margin: 0, fontWeight: "bold" }}
                onClick={() => {
                  setToggleAbout((prevState) => !prevState);
                  if (toggleAirportData) {
                    setToggleAirportData(false);
                  }
                  setShowMenu((prevState) => !prevState);
                }}
                color="inherit"
                variant="text"
                endIcon={<InfoOutlinedIcon />}
              >
                About
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles = {
  main: {
    width: "100%",
    // borderBottom: "solid 1px white",
    position: "-webkit - sticky",
    position: "sticky",
    top: 0,
    backgroundColor: "#282c34",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  h1: {
    textAlign: "left",
    cursor: "pointer",
    marginLeft: 10,
  },
  rightMenu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginRight: 10,
  },
  leftMenu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: 10,
  },
  hover: {
    cursor: "pointer",
    fontWeight: "bold",
  },
};

export default Header;
