import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import { AdContext } from "../context/AdContext";
import Slider from "@mui/material/Slider";

function HtiChart() {
  const contextObj = useContext(AdContext);
  const chart = contextObj.htiChart[0];
  const [chartSorted, setChartSorted] = useState(undefined);
  const [selection, setSelection] = useState(0);
  const [chartTime, setChartTime] = useState(0);

  const calcTime = () => {
    if (chart) {
      const date = new Date(chart[0].createdAt.iso);
      const diff = (Date.now() - date.getTime()) / 1000 / 60;
      setChartTime(Math.floor(diff));
    }
  };

  useEffect(() => {
    if (chart) {
      const temp = chart.sort((a, b) => a.time.localeCompare(b.time));
      setChartSorted(temp);
      calcTime();
    }
  }, [chart]);

  useEffect(() => {
    const interval = setInterval(() => {
      calcTime();
      console.log("interval check");
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  if (chartSorted) {
    return (
      <div>
        <img
          className="img-width"
          style={{ borderRadius: 8 }}
          src={chartSorted[selection].content}
          alt="Triggered lightning chart - from MET Norway"
        />
        <h3>Chart {String(chartTime)} minutes old</h3>
        <h3>{chartSorted[selection].title}</h3>
        <Slider
          sx={{ width: "30%" }}
          onChange={(event) => setSelection(event.target.value)}
          aria-label="Wave chart"
          defaultValue={0}
          valueLabelDisplay="off"
          step={1}
          marks
          min={0}
          max={chartSorted.length - 1}
        />
      </div>
    );
  }
}

export default HtiChart;
