import React, { useContext, useState, useEffect } from "react";
import suncalc from "suncalc";
import { AdContext } from "../context/AdContext";
import '../App.css';

const Suncalc = () => {

    const contextObj = useContext(AdContext);
    const localStorageBase = contextObj.localStorageBase[0];

    const [sunriseSunset, setSunriseSunset] = useState({
        sunrise: "",
        sunset: "",
    });

    useEffect(() => {
        if (localStorageBase) {
            getSunriseSunset(localStorageBase);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorageBase]);

    const getSunriseSunset = (inLocalBase) => {
        let sunrise, sunset;

        switch (inLocalBase) {
            case "Sola":
                sunset = suncalc.getTimes(new Date(), 58.876785, 5.63783);
                sunrise = suncalc.getTimes(new Date(), 58.876785, 5.63783);
                break;
            case "Bergen":
                sunset = suncalc.getTimes(new Date(), 60.293522, 5.218087);
                sunrise = suncalc.getTimes(new Date(), 60.293522, 5.218087);
                break;
            case "Florø":
                sunset = suncalc.getTimes(new Date(), 61.583643, 5.024645);
                sunrise = suncalc.getTimes(new Date(), 61.583643, 5.024645);
                break;
            case "Hammerfest":
                sunset = suncalc.getTimes(new Date(), 70.679639, 23.668593);
                sunrise = suncalc.getTimes(new Date(), 70.679639, 23.668593);
                break;
            case "Ekofisk":
                sunset = suncalc.getTimes(new Date(), 56.543333, 3.225);
                sunrise = suncalc.getTimes(new Date(), 56.543333, 3.225);
                break;
            default:
                sunset = suncalc.getTimes(new Date(), 60.293522, 5.218087);
                sunrise = suncalc.getTimes(new Date(), 60.293522, 5.218087);
        }

        sunset = sunset.dusk;
        sunrise = sunrise.dawn;

        let timeOffset = sunset.getTimezoneOffset();

        sunset.setHours(sunset.getHours() + timeOffset / 60);
        sunrise.setHours(sunrise.getHours() + timeOffset / 60);

        let ssh = checkTime(sunset.getHours());
        let ssm = checkTime(sunset.getMinutes());
        let srh = checkTime(sunrise.getHours());
        let srm = checkTime(sunrise.getMinutes());

        setSunriseSunset({
            sunrise: `${srh}:${srm}`,
            sunset: `${ssh}:${ssm}`,
        });
    };

    const checkTime = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        //Add zero in front of numbers if <10
        return i;
    };

    if (sunriseSunset.sunrise.includes("NaN")) {
        return (
            <div className="Suncalc">
                <p style={{ margin: 0 }}>No Sunrise / Sunset</p>
            </div>
        )
    } else {
        return (
            <div className="Suncalc">
                <p style={{ margin: 0 }}>Sunrise {sunriseSunset.sunrise}Z</p>
                <p style={{ margin: 0, marginTop: 4 }}>Sunset {sunriseSunset.sunset}Z</p>
            </div>
        );
    }
}

const styles = {
    div: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        fontWeight: "bold",
        fontSize: "small",
        marginLeft: 20
    }
}

export default Suncalc;