import React, { useRef, useEffect, useContext } from "react";
import { AdContext } from "../context/AdContext";
const rigList = require("../json/rigList.json");

const Canvas = (props) => {
  const contextObj = useContext(AdContext);
  const metar = contextObj.metar[0];

  const canvasRef = useRef(null);
  const color = {
    red: "rgba(204, 23, 42, 1)",
    green: "rgba(0, 255, 0, 1)",
    yellow: "rgba(255, 255, 0, 1)",
  };

  const draw = (ctx, obj, vel, overlapping) => {
    const start = ((-90 + obj.from) * Math.PI) / 180;
    const slutt = ((-90 + obj.to) * Math.PI) / 180;
    let arcColor = "transparent";
    let radius = 20;

    if (obj.type === "table 2") {
      switch (true) {
        case vel > 45:
          arcColor = color.red;
          break;
        case vel >= 35 && vel <= 45:
          arcColor = color.yellow;
          break;
        default:
          arcColor = color.green;
      }
    } else if (obj.type === "custom table 2") {
      switch (true) {
        case vel > obj.velTo:
          arcColor = color.red;
          break;
        case vel >= obj.velFrom && vel <= obj.velTo:
          arcColor = color.yellow;
          break;
        default:
          arcColor = color.green;
      }
    } else if (obj.type === "no landing") {
      switch (true) {
        case vel > obj.lim:
          arcColor = color.red;
          break;
        default:
          arcColor = color.green;
      }
    } else if (obj.type === "no operations") {
      switch (true) {
        case vel > obj.lim:
          arcColor = color.red;
          break;
        default:
          arcColor = color.green;
      }
    } else if (obj.type === "no operations centrax") {
      switch (true) {
        case vel > obj.lim:
          arcColor = color.red;
          break;
        default:
          arcColor = color.green;
      }
    } else if (obj.type === "no operations WHRU") {
      switch (true) {
        case vel > obj.lim:
          arcColor = color.red;
          break;
        default:
          arcColor = color.green;
      }
    } else if (obj.type === "reduced weight above") {
      arcColor = color.yellow;
      if (overlapping === true) {
        if (obj.overlapping === "caution") {
          radius = 25;
        }
      }
    } else if (obj.type === "reduced weight below") {
      arcColor = color.yellow;
      if (overlapping === true) {
        if (obj.overlapping === "caution") {
          radius = 25;
        }
      }
    } else if (obj.type === "caution") {
      arcColor = color.yellow;
      if (overlapping === true) {
        if (obj.overlapping === "caution") {
          radius = 25;
        }
      }
    }
    ctx.strokeStyle = arcColor;
    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.arc(35, 35, radius, start, slutt);
    ctx.stroke();
  };

  useEffect(() => {
    if (metar) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      metar.forEach((item) => {
        let rigName;
        if (
          props.rig === "ENXK" ||
          props.rig === "ENXB" ||
          props.rig === "ENLE"
        ) {
          rigName = "ENLE";
        } else if (props.rig === "ENFB" || props.rig === "ENFB2") {
          rigName = "ENFB";
        } else {
          rigName = props.rig;
        }
        if (item.ad === rigName) {
          if (item.windDetails) {
            if (
              rigName === "ENLE" &&
              (props.rig === "ENXK" ||
                props.rig === "ENXB" ||
                props.rig === "ENLE")
            ) {
              rigList.forEach((rig) => {
                if (rig.name === props.rig) {
                  rig.lim.forEach((lim) => {
                    if ("overlapping" in lim) {
                      if (lim.overlapping === "caution") {
                        draw(context, lim, item.windDetails.vel, true);
                      }
                    } else {
                      draw(context, lim, item.windDetails.vel, false);
                    }
                  });
                }
              });
            } else if (
              rigName === "ENFB" &&
              (props.rig === "ENFB" || props.rig === "ENFB2")
            ) {
              rigList.forEach((rig) => {
                if (rig.name === props.rig) {
                  rig.lim.forEach((lim) => {
                    if ("overlapping" in lim) {
                      if (lim.overlapping === "caution") {
                        draw(context, lim, item.windDetails.vel, true);
                      }
                    } else {
                      draw(context, lim, item.windDetails.vel, false);
                    }
                  });
                }
              });
            } else if (item.rwy.rig && item.rwy.lim !== undefined) {
              item.rwy.lim.forEach((lim) => {
                if ("overlapping" in lim) {
                  if (lim.overlapping === "caution") {
                    draw(context, lim, item.windDetails.vel, true);
                  }
                } else {
                  draw(context, lim, item.windDetails.vel, false);
                }
              });
            }
          }
        }
      });
    }
  }, [draw, metar]);

  return (
    <canvas
      width={70}
      height={70}
      style={{
        position: "absolute",
        zIndex: 9,
      }}
      ref={canvasRef}
      {...props}
    />
  );
};

export default Canvas;
