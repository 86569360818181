import React, { useState, useEffect, useContext } from "react";
import { AdContext } from "../context/AdContext";
// import { FaLongArrowAltUp } from "react-icons/fa";
import NavigationOutlinedIcon from "@mui/icons-material/NavigationOutlined";
import NavigationIcon from "@mui/icons-material/Navigation";
import Canvas from "./Canvas";
const rigList = require("../json/rigList.json");

const Compass = (props) => {
  const contextObj = useContext(AdContext);
  const metar = contextObj.metar[0];
  const [deg, setDeg] = useState(0);
  const [vel, setVel] = useState(0);
  const [normalWindIfGust, setNormalWindIfGust] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    backgroundColor: "white",
    type: "normal",
  });
  const color = {
    red: "rgba(204, 23, 42, 1)",
    green: "rgba(0, 255, 0, 1)",
    yellow: "rgba(255, 255, 0, 1)",
  };

  useEffect(() => {
    if (metar) {
      metar.forEach((item) => {
        let rigName;
        if (
          props.rig === "ENXK" ||
          props.rig === "ENXB" ||
          props.rig === "ENLE"
        ) {
          rigName = "ENLE";
        } else if (props.rig === "ENFB" || props.rig === "ENFB2") {
          rigName = "ENFB";
        } else {
          rigName = props.rig;
        }
        if (item.ad === rigName) {
          const obj = {
            show: false,
            text: "",
            textColor: "#000",
            type: "",
            backgroundColor: "white",
          };
          if (item.windDetails) {
            setDeg(item.windDetails.dir);
            setVel(item.windDetails.vel);
            if (item.windDetails.normalWindIfGust) {
              setNormalWindIfGust(item.windDetails.normalWindIfGust);
            }
            if (
              props.rig === "ENXK" ||
              props.rig === "ENXB" ||
              props.rig === "ENLE"
            ) {
              if (rigName === "ENLE") {
                rigList.forEach((rig) => {
                  if (rig.name === props.rig) {
                    rig.lim.forEach((lim) => {
                      if (lim.from < lim.to) {
                        if (item.windDetails.vel > 60) {
                          obj.backgroundColor = color.red;
                          obj.text = "No Operations";
                          obj.type = "limit";
                          obj.show = true;
                        } else if (lim.type === "table 2") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to
                          ) {
                            switch (true) {
                              case item.windDetails.vel > 45:
                                obj.backgroundColor = color.red;
                                obj.text = "No Landings";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= 35 &&
                                item.windDetails.vel <= 45 &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "custom table 2") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to
                          ) {
                            switch (true) {
                              case item.windDetails.vel > lim.velTo:
                                obj.backgroundColor = color.red;
                                obj.text = "No Operations";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= lim.velFrom &&
                                item.windDetails.vel <= lim.velTo &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "caution") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to
                          ) {
                            if (obj.type !== ("limit" || "tableTwoCaution")) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Use Caution";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no landing") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Landing";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations centrax") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations if Centrax";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "reduced weight above") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "reduced weight below") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel < lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "no operations if WHRU") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations w/WHRU inop";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        }
                      } else {
                        if (item.windDetails.vel > 60) {
                          obj.backgroundColor = color.red;
                          obj.text = "No Operations";
                          obj.type = "limit";
                          obj.show = true;
                        } else if (lim.type === "table 2") {
                          if (
                            (item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                            (item.windDetails.dir <= lim.to &&
                              item.windDetails.dir >= 0)
                          ) {
                            switch (true) {
                              case item.windDetails.vel > 45:
                                obj.backgroundColor = color.red;
                                obj.text = "No Operations";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= 35 &&
                                item.windDetails.vel <= 45 &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "custom table 2") {
                          if (
                            (item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                            (item.windDetails.dir <= lim.to &&
                              item.windDetails.dir >= 0)
                          ) {
                            switch (true) {
                              case item.windDetails.vel > lim.velTo:
                                obj.backgroundColor = color.red;
                                obj.text = "No Operations";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= lim.velFrom &&
                                item.windDetails.vel <= lim.velTo &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "caution") {
                          if (
                            (item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                            (item.windDetails.dir <= lim.to &&
                              item.windDetails.dir >= 0)
                          ) {
                            if (obj.type !== ("limit" || "tableTwoCaution")) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Use Caution";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no landing") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Landing";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations centrax") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations if Centrax";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "reduced weight above") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "reduced weight below") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel < lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "no operations WHRU") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations w/WHRU inop";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        }
                        // else if (
                        //   lim.type === "Full deck table 1 or zero drop"
                        // ) {
                        //   if (item.windDetails.vel < lim.lim) {
                        //     obj.backgroundColor = color.yellow;
                        //     obj.text = "Highest MTOW, table 1 or zero drop";
                        //     obj.type = "caution";
                        //     obj.show = true;
                        //   } else {
                        //     if (
                        //       obj.type !==
                        //       ("limit" || "tableTwoCaution" || "caution")
                        //     ) {
                        //       obj.backgroundColor = color.green;
                        //     }
                        //   }
                        // }
                      }
                    });
                  }
                });
              }
            } else if (props.rig === "ENFB" || props.rig === "ENFB2") {
              if (rigName === "ENFB") {
                rigList.forEach((rig) => {
                  if (rig.name === props.rig) {
                    rig.lim.forEach((lim) => {
                      if (lim.from < lim.to) {
                        if (item.windDetails.vel > 60) {
                          obj.backgroundColor = color.red;
                          obj.text = "No Operations";
                          obj.type = "limit";
                          obj.show = true;
                        } else if (lim.type === "table 2") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to
                          ) {
                            switch (true) {
                              case item.windDetails.vel > 45:
                                obj.backgroundColor = color.red;
                                obj.text = "No Landings";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= 35 &&
                                item.windDetails.vel <= 45 &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "custom table 2") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to
                          ) {
                            switch (true) {
                              case item.windDetails.vel > lim.velTo:
                                obj.backgroundColor = color.red;
                                obj.text = "No Operations";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= lim.velFrom &&
                                item.windDetails.vel <= lim.velTo &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "caution") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to
                          ) {
                            if (obj.type !== ("limit" || "tableTwoCaution")) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Use Caution";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no landing") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Landing";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations centrax") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations if Centrax";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "reduced weight above") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "reduced weight below") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel < lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "no operations if WHRU") {
                          if (
                            item.windDetails.dir >= lim.from &&
                            item.windDetails.dir <= lim.to &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations w/WHRU inop";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        }
                      } else {
                        if (item.windDetails.vel > 60) {
                          obj.backgroundColor = color.red;
                          obj.text = "No Operations";
                          obj.type = "limit";
                          obj.show = true;
                        } else if (lim.type === "table 2") {
                          if (
                            (item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                            (item.windDetails.dir <= lim.to &&
                              item.windDetails.dir >= 0)
                          ) {
                            switch (true) {
                              case item.windDetails.vel > 45:
                                obj.backgroundColor = color.red;
                                obj.text = "No Operations";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= 35 &&
                                item.windDetails.vel <= 45 &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "custom table 2") {
                          if (
                            (item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                            (item.windDetails.dir <= lim.to &&
                              item.windDetails.dir >= 0)
                          ) {
                            switch (true) {
                              case item.windDetails.vel > lim.velTo:
                                obj.backgroundColor = color.red;
                                obj.text = "No Operations";
                                obj.type = "limit";
                                obj.show = true;
                                break;
                              case item.windDetails.vel >= lim.velFrom &&
                                item.windDetails.vel <= lim.velTo &&
                                obj.type !== "limit":
                                obj.backgroundColor = color.yellow;
                                obj.text = "Table 2 lim weight";
                                obj.type = "tableTwoCaution";
                                obj.show = true;
                                break;
                              default:
                                obj.backgroundColor = color.green;
                                obj.text = "";
                                obj.type = "normal";
                                obj.show = false;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "caution") {
                          if (
                            (item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                            (item.windDetails.dir <= lim.to &&
                              item.windDetails.dir >= 0)
                          ) {
                            if (obj.type !== ("limit" || "tableTwoCaution")) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Use Caution";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no landing") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Landing";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "no operations centrax") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations if Centrax";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        } else if (lim.type === "reduced weight above") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "reduced weight below") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel < lim.lim
                          ) {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.yellow;
                              obj.text = "Reduced weight";
                              obj.type = "caution";
                              obj.show = true;
                            }
                          }
                        } else if (lim.type === "no operations WHRU") {
                          if (
                            ((item.windDetails.dir >= lim.from &&
                              item.windDetails.dir <= 360) ||
                              (item.windDetails.dir <= lim.to &&
                                item.windDetails.dir >= 0)) &&
                            item.windDetails.vel > lim.lim &&
                            obj.type !== "limit"
                          ) {
                            obj.backgroundColor = color.red;
                            obj.text = "No Operations w/WHRU inop";
                            obj.type = "limit";
                            obj.show = true;
                          } else {
                            if (
                              obj.type !==
                              ("limit" || "tableTwoCaution" || "caution")
                            ) {
                              obj.backgroundColor = color.green;
                            }
                          }
                        }
                        // else if (
                        //   lim.type === "Full deck table 1 or zero drop"
                        // ) {
                        //   if (item.windDetails.vel < lim.lim) {
                        //     obj.backgroundColor = color.yellow;
                        //     obj.text = "Highest MTOW, table 1 or zero drop";
                        //     obj.type = "caution";
                        //     obj.show = true;
                        //   } else {
                        //     if (
                        //       obj.type !==
                        //       ("limit" || "tableTwoCaution" || "caution")
                        //     ) {
                        //       obj.backgroundColor = color.green;
                        //     }
                        //   }
                        // }
                      }
                    });
                  }
                });
              }
            } else if (item.rwy.rig && item.rwy.lim !== undefined) {
              item.rwy.lim.forEach((lim) => {
                if (lim.from < lim.to) {
                  if (item.windDetails.vel > 60) {
                    obj.backgroundColor = color.red;
                    obj.text = "No Operations";
                    obj.type = "limit";
                    obj.show = true;
                  } else if (lim.type === "table 2") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to
                    ) {
                      switch (true) {
                        case item.windDetails.vel > 45:
                          obj.backgroundColor = color.red;
                          obj.text = "No Landings";
                          obj.type = "limit";
                          obj.show = true;
                          break;
                        case item.windDetails.vel >= 35 &&
                          item.windDetails.vel <= 45 &&
                          obj.type !== "limit":
                          obj.backgroundColor = color.yellow;
                          obj.text = "Table 2 lim weight";
                          obj.type = "tableTwoCaution";
                          obj.show = true;
                          break;
                        default:
                          obj.backgroundColor = color.green;
                          obj.text = "";
                          obj.type = "normal";
                          obj.show = false;
                      }
                    } else {
                      if (
                        obj.type !== ("limit" || "tableTwoCaution" || "caution")
                      ) {
                        obj.backgroundColor = color.green;
                      }
                    }
                  } else if (lim.type === "custom table 2") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to
                    ) {
                      switch (true) {
                        case item.windDetails.vel > lim.velTo:
                          obj.backgroundColor = color.red;
                          obj.text = "No Operations";
                          obj.type = "limit";
                          obj.show = true;
                          break;
                        case item.windDetails.vel >= lim.velFrom &&
                          item.windDetails.vel <= lim.velTo &&
                          obj.type !== "limit":
                          obj.backgroundColor = color.yellow;
                          obj.text = "Table 2 lim weight";
                          obj.type = "tableTwoCaution";
                          obj.show = true;
                          break;
                        default:
                          obj.backgroundColor = color.green;
                          obj.text = "";
                          obj.type = "normal";
                          obj.show = false;
                      }
                    } else {
                      if (
                        obj.type !== ("limit" || "tableTwoCaution" || "caution")
                      ) {
                        obj.backgroundColor = color.green;
                      }
                    }
                  } else if (lim.type === "caution") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to
                    ) {
                      if (obj.type !== ("limit" || "tableTwoCaution")) {
                        obj.backgroundColor = color.yellow;
                        obj.text = "Use Caution";
                        obj.type = "caution";
                        obj.show = true;
                      }
                    } else {
                      if (
                        obj.type !== ("limit" || "tableTwoCaution" || "caution")
                      ) {
                        obj.backgroundColor = color.green;
                      }
                    }
                  } else if (lim.type === "no landing") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Landing";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  } else if (lim.type === "no operations") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Operations";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  } else if (lim.type === "no operations centrax") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Operations if Centrax";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  } else if (lim.type === "reduced weight above") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to &&
                      item.windDetails.vel > lim.lim
                    ) {
                      if (obj.type !== ("limit" || "tableTwoCaution")) {
                        obj.backgroundColor = color.yellow;
                        obj.text = "Reduced weight";
                        obj.type = "caution";
                        obj.show = true;
                      }
                    }
                  } else if (lim.type === "reduced weight below") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to &&
                      item.windDetails.vel < lim.lim
                    ) {
                      if (obj.type !== ("limit" || "tableTwoCaution")) {
                        obj.backgroundColor = color.yellow;
                        obj.text = "Reduced weight";
                        obj.type = "caution";
                        obj.show = true;
                      }
                    }
                  } else if (lim.type === "no operations if WHRU") {
                    if (
                      item.windDetails.dir >= lim.from &&
                      item.windDetails.dir <= lim.to &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Operations w/WHRU inop";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  }
                } else {
                  if (item.windDetails.vel > 60) {
                    obj.backgroundColor = color.red;
                    obj.text = "No Operations";
                    obj.type = "limit";
                    obj.show = true;
                  } else if (lim.type === "table 2") {
                    if (
                      (item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                      (item.windDetails.dir <= lim.to &&
                        item.windDetails.dir >= 0)
                    ) {
                      switch (true) {
                        case item.windDetails.vel > 45:
                          obj.backgroundColor = color.red;
                          obj.text = "No Operations";
                          obj.type = "limit";
                          obj.show = true;
                          break;
                        case item.windDetails.vel >= 35 &&
                          item.windDetails.vel <= 45 &&
                          obj.type !== "limit":
                          obj.backgroundColor = color.yellow;
                          obj.text = "Table 2 lim weight";
                          obj.type = "tableTwoCaution";
                          obj.show = true;
                          break;
                        default:
                          obj.backgroundColor = color.green;
                          obj.text = "";
                          obj.type = "normal";
                          obj.show = false;
                      }
                    } else {
                      if (
                        obj.type !== ("limit" || "tableTwoCaution" || "caution")
                      ) {
                        obj.backgroundColor = color.green;
                      }
                    }
                  } else if (lim.type === "custom table 2") {
                    if (
                      (item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                      (item.windDetails.dir <= lim.to &&
                        item.windDetails.dir >= 0)
                    ) {
                      switch (true) {
                        case item.windDetails.vel > lim.velTo:
                          obj.backgroundColor = color.red;
                          obj.text = "No Operations";
                          obj.type = "limit";
                          obj.show = true;
                          break;
                        case item.windDetails.vel >= lim.velFrom &&
                          item.windDetails.vel <= lim.velTo &&
                          obj.type !== "limit":
                          obj.backgroundColor = color.yellow;
                          obj.text = "Table 2 lim weight";
                          obj.type = "tableTwoCaution";
                          obj.show = true;
                          break;
                        default:
                          obj.backgroundColor = color.green;
                          obj.text = "";
                          obj.type = "normal";
                          obj.show = false;
                      }
                    } else {
                      if (
                        obj.type !== ("limit" || "tableTwoCaution" || "caution")
                      ) {
                        obj.backgroundColor = color.green;
                      }
                    }
                  } else if (lim.type === "caution") {
                    if (
                      (item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                      (item.windDetails.dir <= lim.to &&
                        item.windDetails.dir >= 0)
                    ) {
                      if (obj.type !== ("limit" || "tableTwoCaution")) {
                        obj.backgroundColor = color.yellow;
                        obj.text = "Use Caution";
                        obj.type = "caution";
                        obj.show = true;
                      }
                    } else {
                      if (
                        obj.type !== ("limit" || "tableTwoCaution" || "caution")
                      ) {
                        obj.backgroundColor = color.green;
                      }
                    }
                  } else if (lim.type === "no landing") {
                    if (
                      ((item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                        (item.windDetails.dir <= lim.to &&
                          item.windDetails.dir >= 0)) &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Landing";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  } else if (lim.type === "no operations") {
                    if (
                      ((item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                        (item.windDetails.dir <= lim.to &&
                          item.windDetails.dir >= 0)) &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Operations";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  } else if (lim.type === "no operations centrax") {
                    if (
                      ((item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                        (item.windDetails.dir <= lim.to &&
                          item.windDetails.dir >= 0)) &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Operations if Centrax";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  } else if (lim.type === "reduced weight above") {
                    if (
                      (item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                      (item.windDetails.dir <= lim.to &&
                        item.windDetails.dir >= 0 &&
                        item.windDetails.vel > lim.lim)
                    ) {
                      if (obj.type !== ("limit" || "tableTwoCaution")) {
                        obj.backgroundColor = color.yellow;
                        obj.text = "Reduced weight";
                        obj.type = "caution";
                        obj.show = true;
                      }
                    }
                  } else if (lim.type === "reduced weight below") {
                    if (
                      (item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                      (item.windDetails.dir <= lim.to &&
                        item.windDetails.dir >= 0 &&
                        item.windDetails.vel < lim.lim)
                    ) {
                      if (obj.type !== ("limit" || "tableTwoCaution")) {
                        obj.backgroundColor = color.yellow;
                        obj.text = "Reduced weight";
                        obj.type = "caution";
                        obj.show = true;
                      }
                    }
                  } else if (lim.type === "no operations WHRU") {
                    if (
                      ((item.windDetails.dir >= lim.from &&
                        item.windDetails.dir <= 360) ||
                        (item.windDetails.dir <= lim.to &&
                          item.windDetails.dir >= 0)) &&
                      item.windDetails.vel > lim.lim &&
                      obj.type !== "limit"
                    ) {
                      obj.backgroundColor = color.red;
                      obj.text = "No Operations w/WHRU inop";
                      obj.type = "limit";
                      obj.show = true;
                    }
                  }
                }
              });
            }
            setMessage(obj);
          } else {
            setMessage("No wind data");
          }
        }
      });
    }
  }, [metar, props.rig]);

  const compass = {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: message.backgroundColor,
    color: "#000",
    textShadow: "1px 1px 1px white",
  };
  const runway = {
    width: "40px",
    height: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: message.backgroundColor,
    color: "#555",
    textShadow: "1px 1px 1px white",
    transform: `rotate(60deg)`,
  };

  const arrow = {
    transform: `rotate(${deg - 180}deg)`,
    // fontSize: 20,
    // height: 20,
    // width: 20,
    color: "#000",
  };

  const limTextContainer = {
    position: "absolute",
    top:
      props.rig === "ENXK" ||
      props.rig === "ENXB" ||
      props.rig === "ENLE" ||
      props.rig === "ENFB" ||
      props.rig === "ENFB2"
        ? 20
        : -8,
    right:
      props.rig === "ENXK" ||
      props.rig === "ENXB" ||
      props.rig === "ENLE" ||
      props.rig === "ENFB" ||
      props.rig === "ENFB2"
        ? 36
        : 100,
    marginRight: 20,
    padding: 3,
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: 10,
    backgroundColor: message.backgroundColor,
    color: message.textColor,
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    // minWidth: 70,
  };

  if (message === "No wind data") {
    return null;
  }
  return (
    <div
      style={
        props.rig === "ENXK" ||
        props.rig === "ENXB" ||
        props.rig === "ENLE" ||
        props.rig === "ENFB" ||
        props.rig === "ENFB2"
          ? ekoContainer
          : container
      }
    >
      {message.show ? <p style={limTextContainer}>{message.text}</p> : null}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Canvas rig={props.rig} />
        <div style={compass}>
          <span style={arrow}>
            <NavigationIcon />
          </span>
        </div>
        <div
          style={{
            borderRadius: "10px",
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            position: "absolute",
            top: -8,
            right: 45,
          }}
        >
          {props.rig !== "ENXK" &&
          props.rig !== "ENXB" &&
          props.rig !== "ENLE" &&
          props.rig !== "ENFB" &&
          props.rig !== "ENFB2" ? (
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#fff",
                minWidth: 50,
                whiteSpace: "nowrap",
              }}
            >
              {normalWindIfGust ? <span>{normalWindIfGust} G </span> : null}
              {vel} KT
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const container = {
  position: "absolute",
  top: 15,
  right: 10,
  // display: "flex",
  // flexDirection: "row",
  // justifyContent: "flex-end",
  // alignItems: "center",
  // paddingTop: 10
};

const ekoContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingTop: 10,
};

export default Compass;
