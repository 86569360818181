import React, { useState, useEffect, useContext } from "react";
import { AdContext } from "../context/AdContext";
import Compass from "./Compass";
const presetAd = require("../json/presetAd.json");

const RigInfoRow = ({ base }) => {
  const contextObj = useContext(AdContext);
  const [rigList, setRigList] = useState(undefined);
  const desktopScreen = contextObj.desktopScreen[0];
  const metar = contextObj.metar[0];

  useEffect(() => {
    if (base === "Ekofisk") {
      presetAd.forEach((item) => {
        if (item.name === "ENLE") {
          setRigList(item);
        }
      });
    }
    if (base === "Statfjord") {
      presetAd.forEach((item) => {
        if (item.name === "ENFB") {
          setRigList(item);
        }
      });
    }
  }, [base]);

  const container = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 20,
    marginLeft: desktopScreen ? "20%" : "6%",
    marginRight: desktopScreen ? "20%" : "6%",
  };

  const container2 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 40,
    marginLeft: desktopScreen ? "20%" : "6%",
    marginRight: desktopScreen ? "20%" : "6%",
  };

  const statfjordName = (rig) => {
    if (rig === "ENFB") {
      return "ENFB SOUTH";
    }
    if (rig === "ENFB2") {
      return "ENFB NORTH";
    }
    return "";
  };

  if (metar && rigList) {
    if (base === "Ekofisk") {
      return (
        <div
          syle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={container}>
            {rigList.airports.map((item, i) => {
              return (
                <div style={limContainer} key={i}>
                  <p style={{ marginRight: 15, marginTop: -25 }}>
                    {item}
                    {item === "ENLE" ? null : "*"}
                  </p>
                  <Compass rig={item} key={i} index={i} type="rig" />
                </div>
              );
            })}
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: 10,
              marginTop: 25,
              marginBottom: 0,
            }}
          >
            *Lim. based on wind from ENLE
          </p>
        </div>
      );
    } else if (base === "Statfjord") {
      return (
        <div
          syle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={container2}>
            {rigList.airports.map((item, i) => {
              return (
                <div style={limContainer} key={i}>
                  <p style={{ marginRight: 15, marginTop: -25 }}>
                    {statfjordName(item)}
                  </p>
                  <Compass rig={item} key={i} index={i} type="rig" />
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  return null;
};

const limContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
};

export default RigInfoRow;
