import adList from "../json/adList.json";
import rigList from "../json/rigList.json";

const REG_METAR_WIND = /\b\d{5}KT\b/s;
const REG_METAR_NO_WIND = /\/\/\/\/\/KT/s;
const REG_METAR_GUST = /\b\d{5}G\d{2}KT\b/s;
const REG_METAR_VRB = /\bVRB\d{2}KT\b/s;
const REG_METAR_VRB_GUST = /\bVRB\d{2}G\d{2}KT\b/s;
const TS = /TS/s;

const stringToWordArray = (objIn) => {
  const obj = { ...objIn };
  if (obj.data !== "No data available") {
    const splitted = obj.data.split(" ");
    obj.weatherWordArray = splitted;
  }
  return obj;
};

const metarWind = (objIn) => {
  const obj = { ...objIn };
  obj.ts = false;
  if ("weatherWordArray" in obj) {
    obj.weatherWordArray.forEach((item) => {
      if (TS.test(item)) {
        obj.ts = true;
      }
      if (!obj.wind) {
        if (REG_METAR_WIND.test(item)) {
          obj.wind = item;
          obj.windDetails = {};
          obj.windDetails.dir = parseInt(item.slice(0, 3), 10);
          obj.windDetails.vel = parseInt(item.slice(3, 5), 10);
          obj.windDetails.type = "normal";
        } else if (REG_METAR_GUST.test(item)) {
          obj.wind = item;
          obj.windDetails = {};
          obj.windDetails.dir = parseInt(item.slice(0, 3), 10);
          const lastG = item.lastIndexOf("G");
          const lastK = item.lastIndexOf("K");
          obj.windDetails.normalWindIfGust = parseInt(item.slice(3, 5), 10);
          obj.windDetails.vel = parseInt(item.slice(lastG + 1, lastK), 10);
          obj.windDetails.type = "gust";
        } else if (REG_METAR_VRB.test(item)) {
          obj.wind = item;
          obj.windDetails = {};
          obj.windDetails.dir = 0;
          obj.windDetails.vel = parseInt(item.slice(3, 5), 10);
          obj.windDetails.type = "vrb";
        } else if (REG_METAR_VRB_GUST.test(item)) {
          obj.wind = item;
          obj.windDetails = {};
          obj.windDetails.dir = 0;
          obj.windDetails.vel = parseInt(item.slice(6, 8), 10);
          obj.windDetails.type = "vrb";
        } else if (REG_METAR_NO_WIND.test(item)) {
          obj.wind = item;
          obj.windDetails = {};
          obj.windDetails.dir = 0;
          obj.windDetails.vel = parseInt(0, 10);
          obj.windDetails.type = "nil";
        }
      }
    });
    if (!obj.wind) {
      obj.wind = "No wind data available";
    }
  }
  return obj;
};

const metarHeadwind = (windDir, windStrength, rwy) => {
  let rwyDir = rwy * 10
  var difference = windDir - rwyDir;
  if (difference > 180) {
      difference = 360 - difference
  }
  let radians = (difference * Math.PI) / 180;
  let headWind = windStrength * Math.cos(radians)
  return headWind;
}

const rwyInUse = (objIn, oneFms) => {
  const obj = { ...objIn };
  const rwyObj = {};
  let rig = false;

  if ("weatherWordArray" in obj) {
    if ("wind" in obj) {
      if(obj.wind !== "No wind data available") {
        adList.forEach((adObj) => {
          if (obj.ad === adObj.name) {
            const tempRwyDir = adObj.one.rwy * 10;
            const difference = Math.abs(obj.windDetails.dir - tempRwyDir);
            let florøAcceptableTailwind = false;
            if(adObj.name === "ENFL"){
              const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.one.rwy);
              if(headwind <= 20){
                  florøAcceptableTailwind = true
              }
          }
            if (
              (difference < 90 || difference > 270) &&
              (obj.windDetails.type === "normal" || obj.windDetails.type === "gust")
            ) {
              if (oneFms && adObj.one.appr.includes("LPV")) {
                if (adObj.oneFms.one.rwy < 10) {
                  rwyObj.rwy = `0${adObj.oneFms.one.rwy}`;
                } else {
                  rwyObj.rwy = adObj.oneFms.one.rwy;
                }
                const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.oneFms.one.rwy);
                rwyObj.headWind = headwind;
                rwyObj.vis = adObj.oneFms.one.vis;
                rwyObj.da = adObj.oneFms.one.da;
                rwyObj.dh = adObj.oneFms.one.dh;
                rwyObj.appr = adObj.oneFms.one.appr;
              } else {
                if (adObj.one.rwy < 10) {
                  rwyObj.rwy = `0${adObj.one.rwy}`;
                } else {
                  rwyObj.rwy = adObj.one.rwy;
                }
                const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.one.rwy);
                rwyObj.headWind = headwind;
                rwyObj.vis = adObj.one.vis;
                rwyObj.da = adObj.one.da;
                rwyObj.dh = adObj.one.dh;
                rwyObj.appr = adObj.one.appr;
              }
            } else if (difference === 90 || difference === 270 || obj.windDetails.type === "vrb") {
              if (adObj.one.dh <= adObj.two.dh) {
                if (oneFms && adObj.one.appr.includes("LPV")) {
                  if (adObj.oneFms.one.rwy < 10) {
                    rwyObj.rwy = `0${adObj.oneFms.one.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.oneFms.one.rwy;
                  }
                  if(obj.windDetails.type === "vrb"){
                    rwyObj.headWind = obj.windDetails.vel;
                  } else {
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.oneFms.one.rwy);
                    rwyObj.headWind = headwind;
                  }
                  rwyObj.vis = adObj.oneFms.one.vis;
                  rwyObj.da = adObj.oneFms.one.da;
                  rwyObj.dh = adObj.oneFms.one.dh;
                  rwyObj.appr = adObj.oneFms.one.appr;
                } else {
                  if (adObj.one.rwy < 10) {
                    rwyObj.rwy = `0${adObj.one.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.one.rwy;
                  }
                  if(obj.windDetails.type === "vrb"){
                    rwyObj.headWind = obj.windDetails.vel;
                  } else {
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.one.rwy);
                    rwyObj.headWind = headwind;
                  }
                  rwyObj.vis = adObj.one.vis;
                  rwyObj.da = adObj.one.da;
                  rwyObj.dh = adObj.one.dh;
                  rwyObj.appr = adObj.one.appr;
                }
              } else {
                if (oneFms && adObj.two.appr.includes("LPV")) {
                  if (adObj.oneFms.two.rwy < 10) {
                    rwyObj.rwy = `0${adObj.oneFms.two.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.oneFms.two.rwy;
                  }
                  if(obj.windDetails.type === "vrb"){
                    rwyObj.headWind = obj.windDetails.vel;
                  } else {
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.oneFms.two.rwy);
                    rwyObj.headWind = headwind;
                  }
                  rwyObj.vis = adObj.oneFms.two.vis;
                  rwyObj.da = adObj.oneFms.two.da;
                  rwyObj.dh = adObj.oneFms.two.dh;
                  rwyObj.appr = adObj.oneFms.two.appr;
                } else {
                  if (adObj.two.rwy < 10) {
                    rwyObj.rwy = `0${adObj.two.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.two.rwy;
                  }
                  if(obj.windDetails.type === "vrb"){
                    rwyObj.headWind = obj.windDetails.vel;
                  } else {
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.two.rwy);
                    rwyObj.headWind = headwind;
                  }
                  rwyObj.vis = adObj.two.vis;
                  rwyObj.da = adObj.two.da;
                  rwyObj.dh = adObj.two.dh;
                  rwyObj.appr = adObj.two.appr;
                }
              }
            } else {
              if (oneFms && adObj.two.appr.includes("LPV")) {
                if(adObj.name === "ENFL"){
                  if(florøAcceptableTailwind){
                    if (adObj.oneFms.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.oneFms.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.oneFms.one.rwy;
                    }
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.oneFms.one.rwy);
                    rwyObj.headWind = headwind;
                    rwyObj.vis = adObj.oneFms.one.vis;
                    rwyObj.da = adObj.oneFms.one.da;
                    rwyObj.dh = adObj.oneFms.one.dh;
                    rwyObj.appr = adObj.oneFms.one.appr;
                  } else {
                    if (adObj.oneFms.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.oneFms.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.oneFms.two.rwy;
                    }
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.oneFms.two.rwy);
                    rwyObj.headWind = headwind;
                    rwyObj.vis = adObj.oneFms.two.vis;
                    rwyObj.da = adObj.oneFms.two.da;
                    rwyObj.dh = adObj.oneFms.two.dh;
                    rwyObj.appr = adObj.oneFms.two.appr;
                  }
                } else {
                  if (adObj.oneFms.two.rwy < 10) {
                    rwyObj.rwy = `0${adObj.oneFms.two.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.oneFms.two.rwy;
                  }
                  const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.oneFms.two.rwy);
                  rwyObj.headWind = headwind;
                  rwyObj.vis = adObj.oneFms.two.vis;
                  rwyObj.da = adObj.oneFms.two.da;
                  rwyObj.dh = adObj.oneFms.two.dh;
                  rwyObj.appr = adObj.oneFms.two.appr;
                }
              } else {
                if(adObj.name === "ENFL"){
                  if(florøAcceptableTailwind){
                    if (adObj.one.rwy < 10) {
                      rwyObj.rwy = `0${adObj.one.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.one.rwy;
                    }
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.one.rwy);
                    rwyObj.headWind = headwind;
                    rwyObj.vis = adObj.one.vis;
                    rwyObj.da = adObj.one.da;
                    rwyObj.dh = adObj.one.dh;
                    rwyObj.appr = adObj.one.appr;
                  } else {
                    if (adObj.two.rwy < 10) {
                      rwyObj.rwy = `0${adObj.two.rwy}`;
                    } else {
                      rwyObj.rwy = adObj.two.rwy;
                    }
                    const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.two.rwy);
                    rwyObj.headWind = headwind;
                    rwyObj.vis = adObj.two.vis;
                    rwyObj.da = adObj.two.da;
                    rwyObj.dh = adObj.two.dh;
                    rwyObj.appr = adObj.two.appr;
                  }
                } else {
                  if (adObj.two.rwy < 10) {
                    rwyObj.rwy = `0${adObj.two.rwy}`;
                  } else {
                    rwyObj.rwy = adObj.two.rwy;
                  }
                  const headwind = metarHeadwind(obj.windDetails.dir, obj.windDetails.vel, adObj.two.rwy);
                  rwyObj.headWind = headwind;
                  rwyObj.vis = adObj.two.vis;
                  rwyObj.da = adObj.two.da;
                  rwyObj.dh = adObj.two.dh;
                  rwyObj.appr = adObj.two.appr;
                }
              }
            }
          }
        });
        rigList.forEach((rigObj) => {
          if (obj.ad === rigObj.name) {
            rwyObj.deckHdg = rigObj.deckHdg;
            rwyObj.deckHeight = rigObj.deckHeight;
            rwyObj.fixedRig = rigObj.fixedRig;
            rwyObj.id = rigObj.id;
            rwyObj.lim = rigObj.lim;
            rig = true;
          }
        });
        if (!(Object.keys(rwyObj).length === 0)) {
          obj.rwy = { rig, ...rwyObj };
        }
      }
    }
  } else {
    
  }
  return obj;
};

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

const crosswind = (objIn) => {
  const obj = { ...objIn };
  if ("rwy" in obj) {
    if (!obj.rwy.rig) {
      if (obj.windDetails.type === "vrb") {
        obj.rwy.crossWind = obj.windDetails.vel;
      } else {
        const rwyDir = obj.rwy.rwy * 10;
        let difference = Math.abs(obj.windDetails.dir - rwyDir);
        if (difference > 180) {
          difference = 360 - difference + obj.windDetails.dir;
        }
        const xwc = Math.sin(toRadians(difference));
        obj.rwy.crossWind = Math.ceil(xwc * obj.windDetails.vel);
      }
    }
  }
  return obj;
};

export default function getMetarRunwayInUse(objIn, oneFms) {
  const obj = { ...objIn };
  const wordArrayRes = stringToWordArray(obj);
  const metarWindRes = metarWind(wordArrayRes);
  const rwyInUseRes = rwyInUse(metarWindRes, oneFms);
  const crosswindRes = crosswind(rwyInUseRes);
  return crosswindRes;
}
