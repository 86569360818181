import React, { useState, useEffect, createContext } from "react";
import {
  fetchMetar,
  processMetar,
  fetchTaf,
  fetchNotam,
  fetchTwoSigcharts,
  fetchWave,
  fetchHti,
  fetchGaf,
} from "../data/FetchFile";
import getMetarRunwayInUse from "../data/getMetarRunwayInUse";
import getTafRunwayInUse from "../data/getTafRunwayInUse";

export const AdContext = createContext();

export function AdProvider({ children }) {
  const [localStorageBase, setLocalStorageBase] = useState("Bergen");
  const [localStorageAirports, setLocalStorageAirports] = useState(undefined);
  const [toggleBase, setToggleBase] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [toggleAirportData, setToggleAirportData] = useState(false);
  const [toggleAbout, setToggleAbout] = useState(false);
  const [toggleGdpr, setToggleGdpr] = useState(false);
  const [metarRaw, setMetarRaw] = useState(undefined);
  const [metar, setMetar] = useState(undefined);
  const [metarOneFms, setMetarOneFms] = useState(undefined);
  const [metarTime, setMetarTime] = useState(undefined);
  const [taf, setTaf] = useState(undefined);
  const [tafNight, setTafNight] = useState(undefined);
  const [tafOneFms, setTafOneFms] = useState(undefined);
  const [tafOneFmsNight, setTafOneFmsNight] = useState(undefined);
  const [tafTime, setTafTime] = useState(undefined);
  const [timeExpired, setTimeExpired] = useState(false);
  const [desktopScreen, setDesktopScreen] = useState(undefined);
  const [wideScreen, setWideScreen] = useState(undefined);
  const [notam, setNotam] = useState(undefined);
  const [showNotam, setShowNotam] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const [sigchart, setSigchart] = useState(undefined);
  const [waveChart, setWaveChart] = useState(undefined);
  const [htiChart, setHtiChart] = useState(undefined);
  const [gafChart, setGafChart] = useState(undefined);
  const [daytime, setDaytime] = useState(true);
  const [oneFms, setOneFms] = useState(false);
  const [reload, setReload] = useState(true);
  const [reloadToggle, setReloadToggle] = useState(true);
  const [reloadNextTime, setReloadNextTime] = useState(undefined);
  const [showCharts, setShowCharts] = useState(false);

  const [weatherLoading, setWeatherLoading] = useState(false);
  const [weatherLoadingError, setWeatherLoadingError] = useState(false);

  //  Fetch weather

  useEffect(() => {
    (async () => {
      try {
        setWeatherLoading(true);
        setWeatherLoadingError(false);

        const localStorageBaseRes = localStorage.getItem("base");
        const daytimeRes = JSON.parse(localStorage.getItem("daytime"));
        const oneFmsRes = JSON.parse(localStorage.getItem("oneFms"));
        const autoReloadRes = JSON.parse(localStorage.getItem("autoReload"));
        if (localStorageBaseRes !== null) {
          setLocalStorageBase(localStorageBaseRes);
        } else {
          localStorage.setItem("base", "Bergen");
        }
        if (daytimeRes !== null) {
          setDaytime(daytimeRes);
        } else {
          localStorage.setItem("daytime", JSON.stringify(true));
        }
        if (oneFmsRes !== null) {
          setOneFms(oneFmsRes);
        } else {
          localStorage.setItem("oneFms", JSON.stringify(false));
        }
        if (autoReloadRes !== null) {
          setReloadToggle(autoReloadRes);
        } else {
          localStorage.setItem("autoReload", JSON.stringify(true));
        }

        const metarRes = await fetchMetar();
        const tafRes = await fetchTaf();
        const notamRes = await fetchNotam();
        const sigchartRes = await fetchTwoSigcharts();
        const waveChartRes = await fetchWave(localStorageBaseRes);
        const htiChartRes = await fetchHti(localStorageBaseRes);
        const gafChartRes = await fetchGaf(localStorageBaseRes);

        setWeatherLoading(false);

        // Process metar
        const processedMetarRes = await processMetar(metarRes);
        setMetarTime(processedMetarRes.time);

        //  Process metar with two FMS
        const metarRunwayInUseRes = processedMetarRes.objArrayWithTime.map(
          (item) => getMetarRunwayInUse(item, false)
        );
        //  Process metar with one FMS
        const metarRunwayInUseResOneFms =
          processedMetarRes.objArrayWithTime.map((item) =>
            getMetarRunwayInUse(item, true)
          );
        // Process taf with two FMS and day
        const tafRunwayInUseRes = tafRes.objArrayWithTime.map((item) =>
          getTafRunwayInUse(item, false, true)
        );
        // Process taf with two FMS and night
        const tafRunwayInUseResnight = tafRes.objArrayWithTime.map((item) =>
          getTafRunwayInUse(item, false, false)
        );
        // Process taf with one FMS and day
        const tafRunwayInUseResOneFms = tafRes.objArrayWithTime.map((item) =>
          getTafRunwayInUse(item, true, true)
        );
        // Process taf with one FMS and night
        const tafRunwayInUseResOneFmsNight = tafRes.objArrayWithTime.map(
          (item) => getTafRunwayInUse(item, true, false)
        );
        setSigchart(sigchartRes);
        setWaveChart(waveChartRes);
        setHtiChart(htiChartRes);
        setGafChart(gafChartRes);
        setMetarRaw(metarRes);
        setMetar(metarRunwayInUseRes);
        setMetarOneFms(metarRunwayInUseResOneFms);
        setTaf(tafRunwayInUseRes);
        setTafNight(tafRunwayInUseResnight);
        setTafOneFms(tafRunwayInUseResOneFms);
        setTafOneFmsNight(tafRunwayInUseResOneFmsNight);
        setNotam(notamRes);
      } catch (err) {
        setWeatherLoading(false);
        setWeatherLoadingError(true);
        console.log("Error occured when fetching");
        console.log(err);
      }
    })();
  }, [reload]);

  useEffect(() => {
    if (reloadToggle) {
      localStorage.setItem("autoReload", JSON.stringify(true));
    } else {
      localStorage.setItem("autoReload", JSON.stringify(false));
    }
  }, [reloadToggle]);

  useEffect(() => {
    if (timeExpired && reloadToggle) {
      console.log("reloading");
      setReload((prevState) => !prevState);
    }
  }, [timeExpired]);

  return (
    <AdContext.Provider
      value={{
        localStorageBase: [localStorageBase, setLocalStorageBase],
        localStorageAirports: [localStorageAirports, setLocalStorageAirports],
        toggleBase: [toggleBase, setToggleBase],
        toggleDropdown: [toggleDropdown, setToggleDropdown],
        toggleAirportData: [toggleAirportData, setToggleAirportData],
        toggleAbout: [toggleAbout, setToggleAbout],
        toggleGdpr: [toggleGdpr, setToggleGdpr],
        metarRaw: [metarRaw, setMetarRaw],
        metar: [metar, setMetar],
        metarOneFms: [metarOneFms, setMetarOneFms],
        metarTime: [metarTime, setMetarTime],
        taf: [taf, setTaf],
        tafNight: [tafNight, setTafNight],
        tafOneFms: [tafOneFms, setTafOneFms],
        tafOneFmsNight: [tafOneFmsNight, setTafOneFmsNight],
        tafTime: [tafTime, setTafTime],
        desktopScreen: [desktopScreen, setDesktopScreen],
        wideScreen: [wideScreen, setWideScreen],
        timeExpired: [timeExpired, setTimeExpired],
        notam: [notam, setNotam],
        showNotam: [showNotam, setShowNotam],
        showChecklist: [showChecklist, setShowChecklist],
        sigchart: [sigchart, setSigchart],
        waveChart: [waveChart, setWaveChart],
        htiChart: [htiChart, setHtiChart],
        gafChart: [gafChart, setGafChart],
        daytime: [daytime, setDaytime],
        oneFms: [oneFms, setOneFms],
        reload: [reload, setReload],
        reloadToggle: [reloadToggle, setReloadToggle],
        reloadNextTime: [reloadNextTime, setReloadNextTime],
        showCharts: [showCharts, setShowCharts],
        weatherLoading: [weatherLoading, setWeatherLoading],
        weatherLoadingError: [weatherLoadingError, setWeatherLoadingError],
      }}
    >
      {children}
    </AdContext.Provider>
  );
}
