import React, { useContext } from "react";
import { AdContext } from "../context/AdContext";

const Detail = ({ metarItem, tafItem }) => {
  const contextObj = useContext(AdContext);
  const metar = contextObj.metarRaw[0];

  function alternateOrNot(sort) {
    if ("status" in sort) {
      if ("color" in sort.status) {
        if (sort.status.color.blue) {
          return (
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#00008b",
                border: `1.5px solid ${sort.backgroundColor}`,
                fontSize: "x-small",
                borderRadius: 5,
                color: "white",
                fontWeight: "bold",
                paddingRight: 4,
                paddingLeft: 4,
                paddingTop: 3,
                paddingBottom: 3,
                marginLeft: 6,
              }}
            >
              Below alternate
            </span>
          );
        }
        return null;
      }
      return null;
    }
    return null;
  }

  function addExtraFuel(sort) {
    if ("status" in sort) {
      if ("color" in sort.status) {
        if (
          sort.status.color.secondDayYellow &&
          sort.status.color.dayYellow &&
          !sort.status.color.thirdDayYellow &&
          !sort.status.color.thirdDaySecondAirportYellow
        ) {
          return (
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                border: `1.5px solid ${sort.backgroundColor}`,
                fontSize: "x-small",
                borderRadius: 5,
                color: "white",
                fontWeight: "bold",
                paddingRight: 4,
                paddingLeft: 4,
                paddingTop: 1,
                paddingBottom: 1,
                marginLeft: 5,
              }}
            >
              Add 340 LBS
            </span>
          );
        }
        return null;
      }
      return null;
    }
    return null;
  }

  const fiveLastMetars = () => {
    const metarArray = [];
    const content = metar.result.content;

    if (metarArray.length < 5) {
      const textPos = content.lastIndexOf(metarItem.ad);
      const lala = content.slice(0, textPos);
      const lele = lala.lastIndexOf("=");
      const string = content.slice(0, lele);
      const newIndex = string.lastIndexOf("=");
      let newString = string.slice(0, newIndex);
      metarArray.push(content.slice(newIndex + 2, lele));
      for (let i = 0; i < 4; i++) {
        const tempIndexOne = newString.lastIndexOf("=");
        const tempStringOne = newString.slice(
          tempIndexOne + 2,
          newString.length
        );
        metarArray.push(tempStringOne);
        newString = newString.slice(0, tempIndexOne);
      }
    }

    return (
      <div style={styles.divFiveMetars}>
        <h4 style={styles.h4}>LAST FIVE METARS</h4>
        {metarArray.map((item, i) => (
          <p style={i < 4 ? styles.p : styles.p}>{item}</p>
        ))}
      </div>
    );
  };

  const tafDetailsRwyInUse = (taf) => {
    const res = [];
    if ("rwy" in taf) {
      if (taf.rwy.headwind < 0) {
        res.push(
          <p style={styles.rwy}>
            Rwy {taf.rwy.rwy}({taf.rwy.appr} - {taf.rwy.da}'({taf.rwy.dh}')/
            {taf.rwy.vis}m) || x-wind {taf.rwy.crossWind}kt || tailwind{" "}
            {Math.abs(Math.round(taf.rwy.headwind))}kt
          </p>
        );
      } else {
        res.push(
          <p style={styles.rwy}>
            Rwy {taf.rwy.rwy}({taf.rwy.appr} - {taf.rwy.da}'({taf.rwy.dh}')/
            {taf.rwy.vis}m) || x-wind {taf.rwy.crossWind}kt || headwind{" "}
            {Math.round(taf.rwy.headwind)}kt
          </p>
        );
      }
      if ("waiving" in taf.rwy) {
        res.push(
          <p style={styles.waiving}>
            #2 sec appr: Rwy {taf.rwy.waiving.rwy}({taf.rwy.waiving.appr} -{" "}
            {taf.rwy.waiving.da}'({taf.rwy.waiving.dh}')/{taf.rwy.waiving.vis}m)
          </p>
        );
      }
    }
    return res;
  };

  const tafDetails = () => {
    if ("sort" in tafItem) {
      const res = [];
      tafItem.sort.content.forEach((taf) => {
        const wxString = taf.wx.join(" ");
        res.push(
          <div>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 5,
                marginBottom: 5,
                backgroundColor: taf.backgroundColor,
                borderRadius: 5,
                color: taf.backgroundColor === "#cc172a" ? "white" : "#344767",
                fontWeight: "bold",
                fontSize: "0.9rem",
                paddingRight: 4,
                paddingLeft: 4,
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              {taf.type ? `${taf.type.join(" ")} ` : null}
              {taf.time} {wxString}
              {alternateOrNot(taf)}
              {addExtraFuel(taf)}
            </p>
            {tafDetailsRwyInUse(taf)}
          </div>
        );
      });
      return res;
    } else {
      return <p style={styles.p}>{tafItem.data}</p>;
    }
  };

  return (
    <div style={styles.div}>
      {fiveLastMetars()}
      <h4 style={styles.tafTitle}>TAF</h4>
      {tafDetails()}
    </div>
  );
};

const styles = {
  div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderLeft: "2px solid white",
    padding: 5,
  },
  divNoBorder: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  divFiveMetars: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 5,
    marginTop: 0,
  },
  h4: {
    padding: 0,
    margin: 0,
    marginBottom: 0,
    marginTop: 0,
  },
  tafTitle: {
    padding: 0,
    margin: 0,
    marginBottom: 0,
    marginTop: 8,
  },
  p: {
    padding: 0,
    margin: 0,
    textAlign: "left",
    fontSize: "0.9rem",
  },
  pUnderline: {
    padding: 0,
    margin: 0,
    textAlign: "left",
    fontSize: "0.9rem",
    borderBottom: "0.1px solid white",
  },
  rwy: {
    padding: 0,
    margin: 0,
    marginBottom: 0,
    textAlign: "left",
    fontSize: "0.9rem",
  },
  waiving: {
    padding: 0,
    margin: 0,
    marginBottom: 5,
    textAlign: "left",
    fontSize: "0.7rem",
  },
};

export default Detail;
