import { useContext } from "react";
import { AdContext } from "../context/AdContext";

function Notam({ airport }) {
    const contextObj = useContext(AdContext);
    const notam = contextObj.notam[0];
    let data = [];

    const notamActiveOrNot = (start, end) => {
        const startTime = new Date(start);
        const endTime = new Date(end);
        const now = new Date();

        const startTimeMs = startTime.getTime();
        const endTimeMs = endTime.getTime();
        const nowMs = now.getTime();

        if (startTimeMs < nowMs && nowMs < endTimeMs) {
            return (
                <div style={styles.div}>
                    <p style={styles.green}>ACTIVE</p>
                    <p style={styles.timeString}>{startTime.toLocaleString('nb')} → {end === "PERM" ? "PERM" : endTime.toLocaleString('nb')}</p>
                </div>
            )
        }

        if (startTimeMs < nowMs && end === "PERM") {
            return (
                <div style={styles.div}>
                    <p style={styles.green}>ACTIVE</p>
                    <p style={styles.timeString}>{startTime.toLocaleString('nb')} → {end === "PERM" ? "PERM" : endTime.toLocaleString('nb')}</p>
                </div>
            )
        }

        if (startTimeMs > nowMs) {
            return (
                <div style={styles.div}>
                    <p style={styles.yellow}>UPCOMING</p>
                    <p style={styles.timeString}>{startTime.toLocaleString('nb')} → {end === "PERM" ? "PERM" : endTime.toLocaleString('nb')}</p>
                </div>
            )
        }

        if (nowMs > endTimeMs) {
            return (
                <div style={styles.div}>
                    <p style={styles.neutral}>EXPIRED</p>
                    <p style={styles.timeString}>{startTime.toLocaleString('nb')} → {end === "PERM" ? "PERM" : endTime.toLocaleString('nb')}</p>
                </div>
            )
        }

        return (
            <div style={styles.div}>
                <p style={styles.neutral}>NOT ACTIVE</p>
                <p style={styles.timeString}>{startTime.toLocaleString('nb')} → {end === "PERM" ? "PERM" : endTime.toLocaleString('nb')}</p>
            </div>
        )
    }

    // let notamReversed = notam.reverse();
    notam.forEach(element => {
        const tempData = [];
        const createdAt = new Date(element.createdAt);
        if (airport === element.airport) {
            if (data.length === 0) { 
                element.notams.forEach(item => {
                    tempData.unshift(<p style={{textAlign: "left", marginTop: 0}}>{item.text}</p>);
                    tempData.unshift(notamActiveOrNot(item.start, item.end));
                })
                tempData.unshift(<p style={{textAlign: "left", marginBottom: 0}}>NOTAMS Updated {createdAt.toLocaleString('nb')}</p>);
                const item = [<div>{tempData}</div>]
                data.push(item)
            }
        }
    });

    return (
        <div>
            {data}
        </div>
    );
}

const styles = {
    div: {
        display: "flex", 
        justifyContent: "flex-start", 
        alignItems: "center",
        marginBottom: 3
    },
    green: {
        color: "#282c34", 
        background: "#3bcc42", 
        padding: 4, 
        marginRight: 10, 
        marginBottom: 0,
        borderRadius: 5, 
        fontSize: 10, 
        fontWeight: "bold"
    }, 
    yellow: {
        color: "#282c34", 
        background: "#ffcb35", 
        padding: 4, 
        marginRight: 10, 
        marginBottom: 0,
        borderRadius: 5, 
        fontSize: 10, 
        fontWeight: "bold"
    },
    neutral: {
        color: "#282c34", 
        background: "white", 
        padding: 4, 
        marginRight: 10, 
        marginBottom: 0,
        borderRadius: 5, 
        fontSize: 10, 
        fontWeight: "bold"
    },
    timeString: {
        marginLeft: 1,
        marginBottom: 0,
        fontSize: 10
    }
}

export default Notam;
