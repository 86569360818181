import posthog from 'posthog-js'
import { useState } from 'react'; // new

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(true); // new
  
  const acceptCookies = () => { 
    posthog.opt_in_capturing();
    setShowBanner(false); // new
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowBanner(false); // new
  };

  return (
    <div>
      {showBanner && ( // new
        <div>
          <p style={{paddingTop: 10, borderTop: "1px solid white"}}>
            We use tracking cookies to understand how you use the product 
            and help us improve it.
            Please accept cookies to help us improve.
          </p>
          <button type="button" onClick={acceptCookies}>
            Accept Cookies
          </button>
          <button type="button" onClick={declineCookies}>
            Decline Cookies
          </button>
        </div>
      )}
    </div>
  );
}

export default CookieBanner;