import { useContext, useEffect, useState } from "react";
import { AdContext } from "../context/AdContext";
import '../App.css';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightIcon from '@mui/icons-material/Nightlight';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';

function TimeWeatherUpdated() {
    const contextObj = useContext(AdContext);
    const metarTime = contextObj.metarTime[0];
    const [timeExpired, setTimeExpired] = contextObj.timeExpired;
    const [updatedDate, setUpdatedDate] = useState("Today");
    const daytime = contextObj.daytime[0];
    const oneFms = contextObj.oneFms[0];
    const reloadToggle = contextObj.reloadToggle[0];
    const showNotam = contextObj.showNotam[0];

    function calcTime(time) {
        if (time) {
            const date1 = new Date(
                Date.UTC(time.year, time.month - 1, time.date, time.hour, time.minute)
            );
            const date2 = new Date();
            const diff = (date2.getTime() - date1.getTime()) / 1000;

            if (date1.getDate === date2.getDate) {
                setUpdatedDate("Today");
            } else {
                setUpdatedDate(`${metarTime.date}/${metarTime.month}`);
            }

            if (diff > 1800) {
                return true;
            }
            if (
                (time.minute < 20 || time.minute >= 50) &&
                date2.getMinutes() >= 20 &&
                date2.getMinutes() < 50
            ) {
                return true;
            }
            if (
                time.minute >= 20 &&
                time.minute < 50 &&
                (date2.getMinutes() >= 50 || date2.getMinutes() < 20)
            ) {
                return true;
            }
            return false;
        }
        return false;
    };

    useEffect(() => {
        if (metarTime) {
            const res = calcTime(metarTime);
            setTimeExpired(res);
            const interval = setInterval(() => {
                const newRes = calcTime(metarTime);
                setTimeExpired(newRes);
            }, 60000);
            return () => clearInterval(interval);
        }
        return undefined;
    }, [metarTime]);

    const styles = {
        div: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        iconDiv: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        icon: {
            fontSize: 16
        },
        h3: {
            color: "white",
            margin: 0,
            fontWeight: "bold"
        },
        p: {
            color: "white",
            margin: 0,
            fontWeight: "bold"
        },
        autoReload: {
            fontSize: "x-small",
            margin: 0,
            padding: 0
        }
    }

    if (metarTime || timeExpired) {
        return (
            <div className="Time">
                {metarTime !== undefined ? (
                    <div style={styles.div}>
                        {timeExpired ? <h2 style={styles.h3}>Weather expired!</h2> : null}
                        <p style={styles.p}>
                            {`${updatedDate} ${metarTime.hour}:${metarTime.minute}z`}
                        </p>
                        <div style={styles.iconDiv}>
                            {daytime ? <LightModeIcon sx={styles.icon} /> : <NightlightIcon sx={styles.icon} />}
                            {oneFms ? <CropSquareIcon sx={styles.icon} /> : <SplitscreenIcon sx={styles.icon} />}
                            {showNotam ? <SubtitlesIcon sx={styles.icon} /> : <SubtitlesOffIcon sx={styles.icon} />}
                            {reloadToggle ? <UpdateIcon sx={styles.icon} /> : <UpdateDisabledIcon sx={styles.icon} />}
                        </div>
                        {/* {reloadToggle ? (
                            // <p style={styles.autoReload}>Reloading: {reloadNextTime.getHours()}:{reloadNextTime.getMinutes()}</p>
                            // <p style={styles.autoReload}>Auto reload active</p>
                        ) : null}  */}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default TimeWeatherUpdated;
