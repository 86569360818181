import React, { useContext, useEffect, useState } from "react";
import '../App.css';
import { AdContext } from "../context/AdContext";
import Slider from '@mui/material/Slider';

function Sigchart() {
    const contextObj = useContext(AdContext);
    const chart = contextObj.sigchart[0];
    const [chartSorted, setChartSorted] = useState(undefined);
    const [selection, setSelection] = useState(0);

    useEffect(() => {
        if (chart) {
            const temp = chart.sort((a, b) => a.time.iso.localeCompare(b.time.iso));
            setChartSorted(temp);
        }
    }, [chart]);


    if (chartSorted) {
        return (
            <div>
                <img
                className="sigchart-width"
                style={{ borderRadius: 8 }}
                src={chartSorted[selection].content}
                alt="Significant weather chart - from MET Norway"
                />
                <h3>{chartSorted[selection].name.slice(-2)}:00z</h3>
                <Slider
                    sx={{width: "30%"}}
                    onChange={(event)=>setSelection(event.target.value)}
                    aria-label="Significant weather chart"
                    defaultValue={0}
                    valueLabelDisplay="off"
                    step={1}
                    marks
                    min={0}
                    max={chartSorted.length-1}
                />
            </div>
        );
    }
};

export default Sigchart;
