import './App.css';
import { AdProvider } from "./context/AdContext";

import Main from './view/Main';

function App() {
  return (
    <AdProvider>
      <div className="App">
        <header className="App-header">
          <Main />
        </header>
      </div>
    </AdProvider>
  );
}

export default App;
