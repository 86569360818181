import React from "react";

const About = () => {
  const colorNeutral = "white";
  const colorRed = "#cc172a";
  const colorYellow = "#ffcb35";
  const colorGreen = "#3bcc42";

  return (
    <div style={styles.main}>
      <h1>ABOUT HELIPLANNER</h1>
      <p style={styles.p}>
        Heliplanner decodes and present current TAF with colorcodes. Colors are
        according to companys waving approvals and minimas based on EASA req.
        The website is intended to match the 'Heliplanner 2' app as much as
        possible, both for UI and functionality. If any deviations are observed,
        please give feedback from the bottom of the page!
      </p>
      <h2>CHANGELOG</h2>
      <h2>v 2.6.0</h2>
      <p style={styles.p}>•</p>
      <h2>v 2.5.0</h2>
      <p style={styles.p}>
        • Bug fix: 'add 340 LBS' correct if night selected.
      </p>
      <h2>v 2.4.0</h2>
      <p style={styles.p}>
        • Red TAF-line caused by crosswind marked with explanatory text.
      </p>
      <p style={styles.p}>• Add ENLE to top overview for Ekofisk.</p>
      <h2>v 2.3.0</h2>
      <p style={styles.p}>• Add ENWV, ENLA and ENHM to Sola airports.</p>
      <p style={styles.p}>• Reorder airports for Ekofisk.</p>
      <p style={styles.p}>
        • Add tailwind caution for METAR when approach in use has tailwind.
        (ENFL only).
      </p>
      <p style={styles.p}>
        • Crosswind caution and warning for METAR: If crosswind above 25kts,
        yellow caution. If above 35, red warning.
      </p>
      <p style={styles.p}>
        • Crosswind warning for TAF: If crosswind above 35, the TAF-line get
        colored red as below minima.
      </p>
      <p style={styles.p}>
        • Find the last five METAR'S for given airport in detail-view. (Press on
        'card' for desired airport on main screen.)
      </p>
      <p style={styles.p}>• Find airport- and rig-data in menu.</p>
      <h2
        style={{
          borderTop: "solid 0.1px white",
          width: "100%",
          textAlign: "left",
          paddingTop: 15,
        }}
      >
        EXPLANATION
      </h2>
      <h3>GENERAL</h3>
      <p style={styles.p}>
        Press relevant 'weather-card' for detailed information.
      </p>
      <h3>TOP BAR</h3>
      <p style={styles.p}>
        • Sunrise/sunset and when presented weather was loaded.
      </p>
      <p style={styles.p}>
        • Sunrise/sunset for the location of the airport selected.
      </p>
      <p style={styles.p}>• Top bar will turn red if METAR expires.</p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>RELOAD</span> does exactly that, reloads
        the weather and charts.
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>CHARTS</span> opens the chart-area where
        different charts are available.
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>MENU</span> presents the current
        conditions for weather-presentation. If 'DAY' is shown, the
        weather-limitaions for daytime apply.
      </p>
      <h3>UPDATE FREQUENCY</h3>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>NOTAM</span> once every hour.
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>SIGCHART</span> every half an hour.
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>HTI and WAVE</span> once every hour.
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>GAF</span> once every hour.
      </p>
      <h2
        style={{
          borderTop: "solid 0.1px white",
          width: "100%",
          textAlign: "left",
          paddingTop: 15,
        }}
      >
        WEATHER DECODING
      </h2>
      <p style={styles.pColors}>
        <span
          style={{
            display: "inline-block",
            backgroundColor: colorGreen,
            border: `1.5px solid black`,
            fontSize: "small",
            borderRadius: 5,
            color: "black",
            fontWeight: "bold",
            paddingRight: 4,
            paddingLeft: 4,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          GREEN
        </span>
        <span> text contain weather at or above waiving/ARA requirements.</span>
      </p>
      <p style={styles.pColors}>
        <span
          style={{
            display: "inline-block",
            backgroundColor: colorYellow,
            border: `1.5px solid black`,
            fontSize: "small",
            borderRadius: 5,
            color: "black",
            fontWeight: "bold",
            paddingRight: 4,
            paddingLeft: 4,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          YELLOW
        </span>
        <span> text contain weather below waiving/ARA requirements.</span>
      </p>
      <p style={styles.pColors}>
        <span
          style={{
            display: "inline-block",
            backgroundColor: colorRed,
            border: `1.5px solid black`,
            fontSize: "small",
            borderRadius: 5,
            color: "white",
            fontWeight: "bold",
            paddingRight: 4,
            paddingLeft: 4,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          RED
        </span>
        <span> text contain weather below weather minima.</span>
      </p>
      <p style={styles.pColors}>
        <span
          style={{
            display: "inline-block",
            backgroundColor: "#00008b",
            border: `1.5px solid black`,
            fontSize: "small",
            borderRadius: 5,
            color: "white",
            fontWeight: "bold",
            paddingRight: 4,
            paddingLeft: 4,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          BLUE
        </span>
        <span>
          {" "}
          text inside other bars contain weather below alternate minima.
        </span>
      </p>
      <p style={styles.pColors}>
        <span
          style={{
            display: "inline-block",
            backgroundColor: "#fff",
            border: `1.5px solid black`,
            fontSize: "small",
            borderRadius: 5,
            color: "black",
            fontWeight: "bold",
            paddingRight: 4,
            paddingLeft: 4,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          Add 340 LBS
        </span>
        <span>
          {" "}
          text inside other bars indicate only waiving #2 is available and you
          need to add fuel.
        </span>
      </p>
      <p style={styles.p}>
        • <span>Wind arrow</span> turns red if above 60 kts offshore or 40 kts
        onshore.
      </p>
      <h3>WAIVING/ARA REQUIREMENTS:</h3>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>(#2) Day/Night:</span> 3km/DH or MDH +
        400' (Cloud ceiling.) - Requires second available approach and
        additional 340lbs of fuel.
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>(#7A) Day/Night:</span> 2,5km/DH or MDH
        + 700' or 1000' above airport (Cloud ceiling.)
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>(#7B) Day:</span> 4km/DH or MDH + 400'
        (Cloud base.)
      </p>
      <h3>OFFSHORE ENROUTE DESCENT REQUIREMENTS:</h3>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>Day:</span> 4km/600' (Cloud base.)
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>Night:</span> 5km/1200' (Cloud base.)
      </p>
      <h2
        style={{
          borderTop: "solid 0.1px white",
          width: "100%",
          textAlign: "left",
          paddingTop: 15,
        }}
      >
        DATA SOURCE
      </h2>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>MET Norway:</span>{" "}
        <a
          style={{ color: "white" }}
          href="https://api.met.no/"
          target="_blank"
        >
          https://api.met.no/
        </a>
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>US FAA:</span>{" "}
        <a
          style={{ color: "white" }}
          href="https://www.faa.gov/data"
          target="_blank"
        >
          https://www.faa.gov/data
        </a>
      </p>
      <h2
        style={{
          borderTop: "solid 0.1px white",
          width: "100%",
          textAlign: "left",
          paddingTop: 15,
        }}
      >
        CONTACT
      </h2>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>Email:</span>{" "}
        <a
          style={{ color: "white" }}
          href="mailto:contact@heliplanner.no?subject='Feedback web 2.0.0'"
          target="_blank"
        >
          contact@heliplanner.no
        </a>
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>Website:</span>{" "}
        <a
          style={{ color: "white" }}
          href="https://www.heliplanner.no"
          target="_blank"
        >
          https://www.heliplanner.no
        </a>
      </p>
      <p style={styles.p}>
        • <span style={styles.boldSpan}>App:</span>{" "}
        <a
          style={{ color: "white" }}
          href="https://apps.apple.com/no/app/heliplanner-2/id6449394602?l=nb"
          target="_blank"
        >
          Heliplanner 2
        </a>
      </p>
      <h2
        style={{
          borderTop: "solid 0.1px white",
          width: "100%",
          textAlign: "left",
          paddingTop: 15,
        }}
      >
        DISCLAIMER
      </h2>
      <p style={styles.p}>
        <span style={styles.boldSpan}>
          The Heliplanner-app and website do not replace certified documents for
          planning. Data may be incorect.
        </span>
      </p>
      <p style={styles.p}>
        <a
          style={{ color: "white", fontWeight: "bold" }}
          href="https://app.websitepolicies.com/policies/view/nrdSFjd9"
          target="_blank"
        >
          Full disclaimer
        </a>
      </p>
    </div>
  );
};

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "100vh",
  },
  p: {
    textAlign: "left",
    margin: 0,
    padding: 0,
  },
  pColors: {
    textAlign: "left",
    margin: 0,
    marginBottom: 5,
    padding: 0,
  },
  boldSpan: {
    fontWeight: "bold",
  },
};

export default About;
